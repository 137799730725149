import React from "react";


export default function CheckBoxList(props) {

    let propsValues = props.values;
    let onChange = props.onChange;
    let value = props.value;
    let handleErrors = props.handleErrors;
    let id = props.id;
    let validationErrors = props.validationErrors;
    let name = props.name;

    let values = propsValues.map((x) => {return(
        <div class="flex flex-row-reverse flex-wrap">
        <input id={x.id} name={name} type="checkbox" checked={value.includes(x.id)} onChange={(e) => {
            if (e.target.checked) {
                onChange([...value, x.id])
            } else {
                value = value.filter(item => item !== x.id);
                onChange(value);
            }
            handleErrors(props.id);
            // if removed - need to remove the item from value array and then set it
            
            }} class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded block"/>
        <label for={x.id} class="ml-3 text-gray-700 text-xs text-right px-1 mb-1 block">{x.label}</label>
      </div>
    )});
    
    return (
        <>
        <div>
            {values}
            <span className="block text-right text-xs mb-2 py-2 text-red-500" >{validationErrors && validationErrors[id]}</span>
        </div>
        </>
    );
}