import React from "react";
import { Route, Switch , Redirect, } from "react-router-dom";
import Home from "./containers/Home";
import Login from "./containers/Login";
import Login2 from "./containers/Login2";
import Login3 from "./containers/Login3";
import PrivateRoute from "./components/PrivateRoute";
import Query from "./containers/Query";
import User from "./containers/User";
import SignIn from "./containers/SignIn";
import Landing from "./containers/Landing";
import Query2 from "./containers/Query2";
import SignIn2 from "./containers/SignIn2";
import UserHome from "./containers/UserHome";
import ResetPassword from "./containers/ResetPassword";

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <Landing />
      </Route>
      <Route exact path="/login">
          <Login/>
      </Route>
      <Route exact path="/signup">
          <SignIn/>
      </Route>
      <Route exact path="/reset-password">
          <ResetPassword/>
      </Route>
      <Route exact path="/landing">
          <Landing/>
      </Route>
      <PrivateRoute exact path="/query"  comp={Query}>
      </PrivateRoute>
      <PrivateRoute exact path="/query2" comp={Query2}>
        </PrivateRoute>
      <PrivateRoute exact path="/user"  comp={User}>
      </PrivateRoute>
      <PrivateRoute exact path="/home"  comp={UserHome}>
      </PrivateRoute>
    </Switch>
  );
}