import React from "react";


export default function PersonalityTestCheckBox(props) {

    let propsValues = props.values;
    let onChange = props.onChange;
    let checkedValues = props.checkedValues;
    let value = props.value;
    let handleErrors = props.handleErrors;
    let id = props.id;
    let validationErrors = props.validationErrors;
    let name = props.name;

    let values = propsValues.map((x) => {return(
        <div class="flex flex-row-reverse mb-1">
        <input id={x.id} name={name} type="checkbox" checked={checkedValues && checkedValues.length > 0 && checkedValues[x.id -1] == true}onChange={(e) => {
            if (e.target.checked) {
                value[x.id - 1] = true;
                onChange(value);
                handleErrors(id);
            } else {
                value[x.id -1] = false;
                onChange(value);
                handleErrors(id);
            }
            // if removed - need to remove the item from value array and then set it
            
            }} class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"/>
        <label for={x.id} class="text-gray-700 text-xs text-right px-1 mb-1">{x.label}</label>
      </div>
    )});
    
    return (
        <>
            <div>
            {values}
            <span className="block text-right text-xs mb-2 py-2 text-red-500" >{validationErrors && validationErrors[id]}</span>
            </div>
        </>
    );
}