import React, { useState, useEffect, setState } from "react";
import { useHistory } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
//import "./Login.css";

import Navbar from "../components/Navbars/AuthNavbar.js";
import { sendUnauthenticatedRequest } from "../components/rest.js";

export default function Login() {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [user, setUser] = useState("");
    const [notFoundUserName, setNotFoundUsername] = useState("");
    const [showPass, setShowPass] = useState(false);
    const [missingUsername, setMissingUsername] = useState("");
    const [emailSentSuccessfully, setEmailSentSuccessfully] = useState("");
  
    const history = useHistory();
    const { t, i18n } = useTranslation();
  
  
    useEffect(() => {
        const loggedInUser = localStorage.getItem("user");
        if (loggedInUser) {
          //console.log(loggedInUser);
          //const foundUser = JSON.parse(loggedInUser);
          const foundUser = loggedInUser;
          setUser(foundUser);          
        }
      });
  
      const handleLogout = () => {
          setUser({});
          setUsername("");
          setPassword("");
          localStorage.clear();
      }
  
    function validateForm() {
      return username.length > 0 && password.length > 0;
    }

    async function handleSendResetPassword() {
      if (!username) {
        setMissingUsername(t('missing_username_reset_password'));
      } else {
        const url = `/user/send_reset_password?username=${username}`
        try {
        let response = await sendUnauthenticatedRequest(url, 'POST', JSON.stringify({}));
        setEmailSentSuccessfully(t('email_sent_successfully'));
        } catch(err) {
          if (err.response.status == 404) {
              setNotFoundUsername(t('wrong_username'));
          } else {
            alert(t('interal_error'));
          }
        }
      }
    }
  
    async function handleSubmit(event) {
      event.preventDefault();
      const user = {username, password};

     
      // call server api to get the user details
      //setUser(response.data);
      const url = '/user/login';
      try {
      //const url = '/user/set';
      let response = await sendUnauthenticatedRequest(url, 'POST', JSON.stringify(user));
      
      //console.log('response data: ', response.data);
      
      setUser({token:response.data.token})
      localStorage.setItem('user', response.data.token);
      let expirationHours = response.data.expiration || 2;
      let expiration = new Date(new Date().setHours(new Date().getHours() + expirationHours));
      localStorage.setItem('expiration', expiration);
      localStorage.setItem('user_state', response.data.user_state);
      let path = 'query';
      if (response.data.user_state == "PROFILED") {
        path = '/home';  
      } 
      history.push(path);
      
      //localStorage.setItem('user', response.data);
      } catch(err) {
        console.log('Login request failed with status code:  ', err.response.status, ' reponse data: ', err.response.data);
        if (err.response.status == 404 || err.response.status == 401) {
          // wrong username
          setNotFoundUsername(t('incorrect_username_or_password'));
        } 
      }
    }
    if (user) {
      let userState = localStorage.getItem('user_state');
      let path = '/user'; 
      if (userState && userState == "PROFILED") {
        path = '/home'; 
      }
      
      history.push(path);
    }
  

  return (
    <>
      <Navbar transparent />
      <main className="profile-page">
        <section className="relative block h-500-px">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover bg-wed-flowers"
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-50 bg-black"
            ></span>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-blueGray-200 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </section>
       
        <section className="pb-20 bg-blueGray-200 -mt-64">
          <div className="container mx-auto">
          <div className="flex content-center items-center justify-center h-full">
         
            <div className="w-full lg:w-6/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words bg-blueGray-50 w-full mb-8 shadow-xl rounded-lg">
                <div className="px-8 py-5 flex-auto">
                    {/* <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-400"> */}
            {/* <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0 mb-6">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center"> */}
     
                  <div className="text-center mt-8">
                  <h6 className="text-blueGray-800 text-center font-queryFont3 font-bold text-2xl mb-10 font-bold">
                  {t("login_title")}
                  </h6>
                  <Form onSubmit={handleSubmit}>
                    <div className="relative w-full mb-10">
                    <Form.Group size="lg" controlId="email">
                    <Form.Label
                        className="block uppercase text-blueGray-600 text-xs font-queryFont3 text-right font-bold mb-2"
                        htmlFor="grid-password"
                      >
                          {t("login_username")}
                      </Form.Label>
                      <Form.Control
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-right text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-160"
                        placeholder={t("login_username")}
                        value={username}
                        onChange={(e) => {setNotFoundUsername("");
                        setMissingUsername("");
                        setUsername(e.target.value)}}
                      />
                      </Form.Group>
                    </div>

                    <div className="relative w-full mb-10">
                    <Form.Group size="lg" controlId="password">
                    <Form.Label
                        className="block uppercase text-blueGray-600 font-queryFont3 text-xs text-right font-bold mb-2"
                        htmlFor="grid-password"
                      >
                         {t("login_password")}
                        </Form.Label>
                     <span>
                      <Form.Control
                        type={showPass ? "text" :"password"}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-right rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder={t("login_password")}
                        value={password}
                        onChange={(e) => {setNotFoundUsername(""); setEmailSentSuccessfully(""); setPassword(e.target.value)}}
                       
                      />
                    { showPass? 
                    (<i className="absolute fas fa-eye-slash left-0 pl-3 items-center mt-3" onClick={() => setShowPass(false)}></i>):
                    (<i className="absolute fas fa-eye left-0 pl-3 items-center mt-3" onClick={() => setShowPass(true)}></i>)
                    }
                    </span>   
                     
                    </Form.Group>
                    
                    {/* { showPass? 
                     (<i className="fas fa-eye-slash" onClick={setShowPass(false)}></i>):
                     (<i className="fas fa-eye" onClick={setShowPass(true)}></i>)
                      } */}
                    </div>
                    <div className="text-center mt-10 mb-8">
                      <button
                        className="bg-blueGray-800 font-queryFont3 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                        type="submit"
                      >
                      {t("login_submit")}
                      </button>
                    </div>
                    <span className="block text-right text-xs mb-2 py-2 text-red-500" >{notFoundUserName}</span>
                    </Form>

                  </div>
                  </div>
            
                  </div>
                  </div>
                  </div>
                  </div>

                  <div className="flex flex-wrap mt-6 items-center content-center justify-center">
                <div className="text-right font-queryFont3 text-xl">
                  <Link to="/signup" className="text-blueGray-800 ">
                    <small> {t("create_new_account")}</small>
                  </Link>
                </div>
            </div>
            <div className="flex flex-wrap mt-12 items-center content-center justify-center">
            <div className="text-center font-queryFont3 text-sm">

              <span>{t("forgot_password")}</span>
              </div>
              </div>
              <div className="text-center mt-6">
                  <button
                       className="bg-blueGray-700 text-white font-queryFont3 active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        onClick={handleSendResetPassword}
                      >
                      {t("reset_password_submit")}
                      </button>
                      <span className="block text-center text-xs mb-2 py-2 text-red-500" >{missingUsername}</span>
                </div>
                <span className="block text-center font-queryFont3 text-sm   mb-2 py-2" >{emailSentSuccessfully}</span>
                
               
         
        </section>
        
      </main>
      {/* <Footer /> */}
    </>
  );
}




