import React, {useState, useEffect} from "react";
import { useTranslation } from "react-i18next";
import "./QueryCard.css";
import CheckBoxList from "../CheckBoxList";
import PersonalityTestCheckBox from "../PersoanlityTestCheckBox";
import SimpleDropDown from "../Dropdowns/SimpleDropDown";
import RadioButtonList from "../RadioButtonList";
import { RaceDropDown, GetRaceAggreementArray, GetProfessionsList } from "./QueryComponents";
import { assertExpressionStatement } from "@babel/types";
import {sendPostRequest, sendFormDataRequest} from "../rest";
import { useHistory } from "react-router-dom";
import Popup from 'react-popup';
import { couldStartTrivia } from "typescript";
// components

export default function QueryCard() {

  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [validationErrors, setValidationErrors] = useState([]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  
  const [gender, setGender] = useState("");
  const [birthMonth, setBirthMonth] = useState(undefined);
  const [birthYear, setBirthYear] = useState(undefined);
  const [yearsUp, setYearsUp] = useState(undefined);
  const [yearsDown, setYearsDown] = useState(undefined);
  const [fatherRace, setFatherRace] = useState(undefined);
  const [motherRace, setMotherRace] = useState(undefined);
  const [fatherRaceAgreement, setFatherRaceAgreement] = useState("");
  const [motherRaceAgreement, setMotherRaceAgreement] = useState("");
  const [height, setHeight] = useState(undefined)
  const [heightAgreementLess, setHeightAgreementLess] = useState(false)
  const [heightAgreementSame, setHeightAgreementSame] = useState(false);

  const [fat, setFat] = useState("");
  const [skinColor, setSkinColor] = useState("");
  const [darkSkinBother, setDarkSkinBother] = useState(undefined);
  const [fatherName, setFatherName] = useState("");
  const [motherName, setMotherName] = useState("");
  const [smoker, setSmoker] = useState("");
  
  const [fromAbroad, setFromAbroad] = useState("");
  const [nation, setNation] = useState(""); 
  const [nationAgreement, setNationAgreement] = useState([]); 
  const [school, setSchool] = useState(undefined);
  const [chasid, setChasid] = useState(undefined);
  const [profession, setProfession] = useState("");
  const [professionsAgreement, setProfessionsAgreement] = useState([]);
  
  const [openLevel, setOpenLevel] = useState("");
  const [halacha, setHalacha] = useState("");
  const [halacaHakpada, setHalachaHakpada] = useState([]);
  const [bread, setBread] = useState("");
  const [internet, setInternet] = useState("");
  const [movies, setMovies] = useState("");
  const [smartphone, setSmartphone] = useState("");
  const [principles, setPrincipels] = useState([]);
  const [positivePrinciples, setPositivePrincipels] = useState([]);
  const [modestyLevel, setModestyLevel] = useState("");
  const [socks, setSocks] = useState("");
  const [eyesSaving, setEyesSaving] = useState("");
  const [rabays, setRabays] = useState("");
  const [kashrut, setKashrut] = useState("");
  const [toraPlans, setToraPlans] = useState("");
  const [toraPlansAgreement, setToraPlansAgreement] = useState([]);
  const [toraSacrifice, setToraSacrifice] = useState("");
  const [toraHatmada, setToraHatmada] = useState("");
  
  const [interestsList, setInterestsList] = useState([]);
  
  const [hobbiesList, setHobbiesList] = useState([]);
  const [smartness, setSmartness] = useState("");
  const [intellegnce, setIntellegnce] = useState("");
  const [appearnce, setAppearance] = useState("");
  const [societyInfluece, setSocietyInfluence] = useState("");
  const [lifestyleLevelToday, setLifestyleLevelToday] = useState("");
  const [lifestyleLevelFuture, setLifestyleLevelFuture] = useState("");
  const [livingLocationPrefer, setLivingLocationPrefer] = useState("");
  const [livingLocationToday, setLivingLocationToday] = useState("");
  const [offersAreaPrefer, setOffersAreaPrefer] = useState("");
  const [livingNegs, setLivingNegs] = useState([]);
  const [locationObligation, setLocationObligation] = useState("");
  const [abroadPlans, setAbroadPlans] = useState(undefined);
  const [familyImportance, setFamilyImportance] = useState("");
  const [moneyDemands, setMoneyDemands] = useState("");
  const [moneyGives, setMoneyGives] = useState("");
  const [familyDefinition, setFamilyDefinition] = useState("");
  const [familySize, setFamilySize] = useState("");
  const [smallFamilyBother, setSmallFamilyBother] = useState(undefined);
  const [hasNoshrim, setHasNoshrim] = useState(undefined);
  const [workersBrothers, setWorkersBrothers] = useState(undefined);
  const [fatherWork, setFatherWork] = useState("");
  const [motherWork, setMotherWork] = useState("");
  const [simpleFamilyBother, setSimpleFamilyBother] = useState(undefined);
  const [yichusMatters, setYichusMatters] = useState(undefined);
  const [moneyDemandsNumber, setMoneyDemandsNumber] = useState(0);
  const [moneyGivesNumber, setMoneyGivesNumber] = useState(0);
  const [aboutMe, setAboutMe] = useState("");
  const [marketTime, setMarketTime] = useState("");
  const [professionStatus, setProfessionStatus]= useState("");
  const [cancelShiduch, setCancelShiduch] = useState(undefined);
  const [devorsedParents, setDevorsedParents] = useState(undefined);
  const [politicRelation, setPoliticRelation] = useState(undefined);
  const [headCover, setHeadCover] = useState(undefined);
  const [siblingsSchools, setSiblingsSchools] = useState(undefined);
  const [schoolType, setSchoolType] = useState(undefined);
  const [talitPray, setTalitPray] = useState(undefined);

  const [selectedFile, setSelectedFile] = useState(null);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [saveButtonDisabed, setSavedButtonDisabled] = useState(false);
  const [shul, setShul] = useState(undefined);
  const [shadchanCode, setShadchanCode] = useState(undefined);


  useEffect(() => {
    const getUserProfile = async () => {
    
        let userState = localStorage.getItem('user_state');
        
        if (userState && userState == "PROFILED") {
          let url = '/user/profile/me';
          try {
            let profile = await sendPostRequest(url, 'GET');
            if (profile) {
              console.log(`This is the profile from backend: ${JSON.stringify(profile)}`)
              profile = profile.data.profile
              console.log('Profile first Name: ', profile.firstName);
              profile.lastName && setLastName(profile.lastName);
              profile.firstName && setFirstName(profile.firstName);
              profile.phone && setPhone(profile.phone);
              profile.email && setEmail(profile.email);
              let genderToSet = profile.gender == "MALE" ? 'Male' : 'Female';
              profile.gender && setGender(genderToSet);
              let month = new Date(profile.birthdate).getMonth()
              let year = new Date(profile.birthdate).getFullYear()
              // console.log('month: ', month, ' year: ', year);
              setBirthMonth(month);
              setBirthYear(year);
              profile.fatherRaceAgreement && setFatherRaceAgreement(profile.fatherRaceAgreement.map(x=> x.toLowerCase()));
              profile.motherRaceAgreement && setMotherRaceAgreement(profile.motherRaceAgreement.map(x=> x.toLowerCase()));
              profile.fat && setFat(profile.fat.toLowerCase());
              profile.fatherName && setFatherName(profile.fatherName);
              profile.motherName && setMotherName(profile.motherName);
              profile.profession && setProfession(profile.profession.toLowerCase());
              profile.professionStatus && setProfessionStatus(profile.professionStatus.toLowerCase());
              let professionsAgreementOpposite = []
              if (profile.professionsAgreement) {
                    console.log('profession list: ', professionsList)
                    for (let i = 0; i< professionsList.length; i++) {
                      if (!profile.professionsAgreement.includes(professionsList[i].id.toUpperCase())) {
                          console.log('profession id to add: ', professionsList[i].id)
                          professionsAgreementOpposite.push(professionsList[i].id)
                      }
                    }
              }
              console.log('profession Agreement opposite: ', professionsAgreementOpposite)
              

              profile.professionsAgreement && setProfessionsAgreement(professionsAgreementOpposite);
              profile.openLevel && setOpenLevel(profile.openLevel.toLowerCase());
              profile.internet && setInternet(profile.internet.toLowerCase());
              profile.smartphone && setSmartphone(profile.smartphone.toLowerCase());
              profile.movies && setMovies(profile.movies.toLowerCase());
              profile.principles && setPrincipels(profile.principles.map(x=> x.toLowerCase()));
              profile.modestyLevel && setModestyLevel(profile.modestyLevel.toLowerCase());
              profile.socks && setSocks(profile.socks.toLowerCase());
              profile.eyesSaving && setEyesSaving(profile.eyesSaving.toLowerCase());
              profile.rabays && setRabays(profile.rabays.toLowerCase());
              profile.kashrut && setKashrut(profile.kashrut.toLowerCase());
              profile.toraPlansAgreement && setToraPlansAgreement(profile.toraPlansAgreement.map(x=> x.toLowerCase()));
              profile.toraSacrifice && setToraSacrifice(profile.toraSacrifice.toLowerCase());
              profile.interests && setInterestsList(profile.interests.map(x=> x.toLowerCase()));
              profile.hobbies && setHobbiesList(profile.hobbies.map(x=> x.toLowerCase()));
              profile.smartness && setSmartness(profile.smartness.toLowerCase());
              profile.intellegnce && setIntellegnce(profile.intellegnce.toLowerCase());
              profile.appearnce && setAppearance(profile.appearnce.toLowerCase());
              profile.societyInfluence && setSocietyInfluence(profile.societyInfluence.toLowerCase());
              profile.lifestyleLevelToday && setLifestyleLevelToday(profile.lifestyleLevelToday.toLowerCase());
              profile.lifestyleLevelFuture && setLifestyleLevelFuture(profile.lifestyleLevelFuture.toLowerCase());
              profile.livingNegs && setLivingNegs(profile.livingNegs.map(x=> x.toLowerCase()));
              profile.livingLocationPrefer && setLivingLocationPrefer(profile.livingLocationPrefer.toLowerCase());
              profile.livingObligation && setLocationObligation(profile.livingObligation.toLowerCase());
              profile.familyDefinition && setFamilyDefinition(profile.familyDefinition.toLowerCase());
              profile.familySize && setFamilySize(profile.familySize.toLowerCase());
              profile.familyImportance && setFamilyImportance(profile.familyImportance.toLowerCase());
              profile.fatherWork && setFatherWork(profile.fatherWork.toLowerCase());
              profile.motherWork && setMotherWork(profile.motherWork.toLowerCase());
              profile.moneyGives && setMoneyGives(profile.moneyGives.toLowerCase());
              // profile.moneyDemands && setMoneyDemands(profile.moneyDemands.toLowerCase());
              profile.moneyDemandsString && setMoneyDemands(profile.moneyDemandsString.toLowerCase());
              profile.moneyDemandsNumber && setMoneyDemandsNumber(profile.moneyDemandsNumber);
              profile.moneyGivesNumber && setMoneyGivesNumber(profile.moneyGivesNumber);
              profile.smallFamilyBother != undefined && setSmallFamilyBother(profile.smallFamilyBother);
              profile.simpleFamilyBother != undefined && setSimpleFamilyBother(profile.simpleFamilyBother);
              profile.hasNoshrim != undefined && setHasNoshrim(profile.hasNoshrim);
              profile.workersBrothers != undefined && setWorkersBrothers(profile.workersBrothers);
              profile.yichusMatters != undefined && setYichusMatters(profile.yichusMatters);
              profile.devorsedParents != undefined && setDevorsedParents(profile.devorsedParents);
              profile.cancelShiduch != undefined && setCancelShiduch(profile.cancelShiduch);
              profile.chasid != undefined && setChasid(profile.chasid);
              
              // console.log('heightAgreementLess: ', profile.heightAgreementLess);
              profile.heightAgreementLess != undefined && setHeightAgreementLess(profile.heightAgreementLess);
              profile.heightAgreementSame != undefined && setHeightAgreementSame(profile.heightAgreementSame);
              profile.smoker != undefined && setSmoker(profile.smoker.toLowerCase());
              profile.halacha && setHalacha(profile.halacha);
              profile.halachaHakpada && setHalachaHakpada(profile.halachaHakpada.map(x=> x.toLowerCase()));
              profile.bread && setBread(profile.bread.toLowerCase());
              profile.aboutMe && setAboutMe(profile.aboutMe);
              profile.personalityTest && setPersonalityTestList(profile.personalityTest);
              profile.rangeAgeUp && setYearsUp(profile.rangeAgeUp);
              profile.rangeAgeDown && setYearsDown(profile.rangeAgeDown);
              profile.height && setHeight(profile.height);
              profile.school && setSchool(profile.school.toLowerCase());
              profile.fatherRace && setFatherRace(profile.fatherRace.toLowerCase());
              profile.motherRace && setMotherRace(profile.motherRace.toLowerCase());
              profile.fromAbroad && setFromAbroad(profile.fromAbroad.toLowerCase());
              profile.nation && setNation(profile.nation.toLowerCase());
              profile.nationAgreement && setNationAgreement(profile.nationAgreement.map(x=> x.toLowerCase()));
              profile.marketTime && setMarketTime(profile.marketTime.toLowerCase());
              profile.darkBother != undefined && setDarkSkinBother(profile.darkBother);
              profile.skinColor && setSkinColor(profile.skinColor.toLowerCase());
              profile.toraPlans && setToraPlans(profile.toraPlans.toLowerCase());
              profile.toraHatmada && setToraHatmada(profile.toraHatmada.toLowerCase());
              profile.positivePrinciples && setPositivePrincipels(profile.positivePrinciples.map(x=> x.toLowerCase()));
              profile.abroadPlans != undefined && setAbroadPlans(profile.abroadPlans);
              profile.livingLocationToday && setLivingLocationToday(profile.livingLocationToday.toLowerCase());
              profile.offersAreaPrefer && setOffersAreaPrefer(profile.offersAreaPrefer.toLowerCase());
              profile.politicRelation && setPoliticRelation(profile.politicRelation.toLowerCase());
              profile.headCover && setHeadCover(profile.headCover.toLowerCase());
              profile.siblingsSchools && setSiblingsSchools(profile.siblingsSchools.toLowerCase());
              profile.schoolType && setSchoolType(profile.schoolType.toLowerCase());
              profile.shulType && setShul(profile.shulType.toLowerCase());
              profile.talitPray != undefined && setTalitPray(profile.talitPray);
              profile.shadchanCode && setShadchanCode(profile.shadchanCode);
            }
          } catch(err) {
            console.error('Failed to get profile from backend, err: ', err)
          }
        }
     } 
     getUserProfile();
  }, []);

  let persoanlityTestDefault = [];
  for (let i = 0; i < 37; i++) {
    persoanlityTestDefault.push(false);
  }
  
  const [personalityTestList, setPersonalityTestList] = useState(persoanlityTestDefault);
  //console.log("presonality test default: " , personalityTestList);
  
  const fatOptions = [{id: 'thin', label: t('thin')}, {id: 'average', label: gender && gender == "Female" ?  t('average_female') : t('average')}, {id: 'fat', label: gender && gender == "Female" ? t('fat_female'): t('fat')}];
  const skinColors = [{id: 'dark', label: t('dark')}, {id: 'average', label: t('average')},{id: 'bright', label: t('bright')}];
  const halachaHakpadaMale = [{id: 'rabenu_tam', label: t('rabenu_tam')}, {id: 'minyan', label: t('minyan')},  {id: 'halacha_learn', label: t('halacha_learn')}, {id: 'electricity', label: t('electricity_shabbat')}, {id: 'eruv', label: t('eruv')}];
  const breadOptions = [{id: 'growing', label: t('growing')}, {id: 'mishcha', label: t('mishcha')}, {id: 'machine', label: t('machine')}, {id: 'machine_hamur', label: t('machine_chamur')}];
  const yesNoOptions = [{id: true, label: t('yes')}, {id: false, label: t('no')}];
  const smokeOptions = [{id: 'yes', label: t('yes')}, {id: 'no', label: t('no')} , {id: 'touching', label: t('touching')}];


  const personalityTestQuestions = [{id: 1, label: t('p_question_1')}, {id: 2, label: t('p_question_2')}, {id: 3, label: t('p_question_3')}, {id: 4, label: t('p_question_4')}, {id: 5, label: t('p_question_5')}, {id: 6, label: t('p_question_6')}, {id: 7, label: t('p_question_7')}, {id: 8, label: t('p_question_8')}, {id: 9, label: t('p_question_9')}, {id: 10, label: t('p_question_10')}, {id: 11, label: t('p_question_11')}, {id: 12, label: t('p_question_12')}, {id: 13, label: t('p_question_13')}, {id: 14, label: t('p_question_14')}, {id: 15, label: t('p_question_15')}, {id: 16, label: t('p_question_16')}, {id: 17, label: t('p_question_17')}, {id: 18, label: t('p_question_18')}, {id: 19, label: t('p_question_19')}, {id: 20, label: t('p_question_20')}, {id: 21, label: t('p_question_21')}, {id: 22, label: t('p_question_22')}, {id: 23, label: t('p_question_23')}, {id: 24, label: t('p_question_24')}, {id: 25, label: t('p_question_25')}, {id: 26, label: t('p_question_26')}, {id: 27, label: t('p_question_27')}, {id: 28, label: t('p_question_28')}, {id: 29, label: t('p_question_29')}, {id: 30, label: t('p_question_30')}, {id: 31, label: t('p_question_31')}
  ,{id: 32, label: t('p_question_32')}, {id: 33, label: t('p_question_33')}, {id: 34, label: t('p_question_34')}, {id: 35, label: t('p_question_35')}, {id: 36, label: t('p_question_36')}, {id: 37, label: t('p_question_37')}];

  const marketTimeOptions = [{id: 'now', label: t('now')}, {id: 'hanuca', label: t('hanuca')}, {id: 'tu_bishvat', label: t('tu_bishvat')}, {id: 'purim', label: t('purim')}, {id: 'after_pessach', label: t('after_pessach')}];
  
  const familySizeOptions = [{id: 'big', label: t('big')}, {id: 'average', label: t('average_female')}, {id: 'small', label: t('small')}, {id: 'single', label: t('single')}];
  const fatherWorkOptions = [{id: 'no', label: t('no_work')}, {id: 'tech', label: t('tech_work')}, {id: 'hands', label: t('hands_work')}, {id: 'human', label: t('human_work')}, {id: 'teaching', label: t('teaching')}, {id: 'business', label: t('business')}];
  const motherWorkOptions = [{id: 'no', label: t('no_work_female')}, {id: 'tech', label: t('tech_work')}, {id: 'hands', label: t('hands_work')}, {id: 'human', label: t('human_work')}, {id: 'teaching', label: t('teaching')}, {id: 'business', label: t('business')}];
  // const seminarsArray = [t("lustig"), t("yashan"), t("hadash"), t("bnot_elish"), t("snif"), t("maalot"), t("ofakim"), t("volf"), t("kahana"), t("meir"), t("hildesaymer"), t("sharanski"), t("mivchar"), t("shtraus"), t("daat"), 
  // t("shvilim"), t("vingeit"), t("kuk"), t("afikey_daat"), t("bnot_sara"), t("ntiv_hachinuch"), t("bnot_hayil"), t("zuker"), t("ladat_chochma"),
  //  t("zimerman"), t("hager"), t("foyreshtein"), t("yashan_kiryat_sefer"), t("meir_kiryat_sefer"), t("beit_bina"), t("darchey_sara"), t("shoshanim"), t("beit_bracha"), t("shalhevet")];

   const seminarsArray = [ "ofakim", "afikey_daat", "beit_bina", "beit_bracha", "beit_yaakov_rechasim", "bnot_elish", "bnot_hayil", "bnot_sara", "daat",  "darchey_sara", "hager", "hadash", "hildesaymer", "volf", "vingeit", 
   "yashan", "yashan_kiryat_sefer", "kahana", "ladat_chochma", "lustig",  "meir","mivchar", "maalot",  "ntiv_hachinuch", "snif", "foyreshtein", "zuker", "zimerman", "kuk", "shvilim", "shoshanim", "shtraus", "shalhevet", "sharanski", "OTHER"];
  const professionsList = GetProfessionsList(gender);
  const professionStatusOptions = [{id: 'working', label: t('working')}, {id: 'studying', label: t('studying')}, {id: 'on_market', label: t('on_market')}];

  const raceArray = ["ashkenaz", "irak", "maroko", "suria", "tunis",  "paras", "buchara", "tripoli", "algir", "gruzya", "kavkaz", "tureki", "curdi", "afgenistan", "levanon", "yamen_zana","yamen", "other"];
  const raceAgreementArray = [{id: 'ashkenaz', label: t('ashkenaz')}, {id: 'irak', label: t('irak')}, {id: 'maroko', label: t('maroko')}, {id: 'suria', label: t('suria')}, {id: 'tunis', label: t('tunis')}, {id: 'paras', label: t('paras')}, {id: 'tripoli', label: t('tripoli')}, {id: 'buchara', label: t('buchara')}, {id: 'algir', label: t('algir')} ,{id: 'gruzya', 
  label: t('gruzya')}, {id: 'kavkaz', label: t('kavkaz')}, {id: 'tureki', label: t('tureki')}, {id: 'curdi', label: t('curdi')}, {id: 'afgenistan', label: t('afgenistan')}, {id: 'levanon', label: t('levanon')}, {id: 'yamen_zana', label: t('yamen_zana')}, {id: 'yamen', label: t('yamen')}];
  
  const interests = [{id: 'politics', label: t('politics')}, {id: 'history', label: t('history')}, {id: 'science', label: t('science')}, 
  {id: 'philosophy', label: t('philosophy')}, {id: 'TECH', label: t('tech')}, {id: 'humanity', label: t('humanity')}, {id: 'global', label: t('global')}, 
  {id: 'art', label: t('art')}, {id: 'music', label: t('music')}, {id: 'musar', label: t('musar')}];
  const hobbies = [{id: 'reading', label: t('reading')}, {id: 'writing', label: t('writing')}, {id: 'MUSIC', label: t('music')}, {id: 'cooking', label: t('cooking')}, 
  {id: 'sport', label: t('sports')}, {id: 'painting', label: t('painting')}, {id: 'learning', label: t('reading_learn')}, 
  {id: 'extreem_activity', label: t('extrim')}, {id: 'trips', label: t('trips')}, {id: 'langs', label: t('languages')}, {id: 'puzzels', label: t('puzzels')}, 
  {id: 'board_games', label: t('board_games')}, {id: 'volunteering', label: t('volunteering')}];

  const smartnessOptions = [{id: 'genious', label: t('genious')}, {id: 'very_smart', label: gender && gender == "Female" ? t('very_smart'): t('very_talented')}, {id: 'smart', label: gender && gender == "Female" ? t('smart'): t('talented')} , {id: 'standart', label: t('standart')}];
  const intellegnceOptions = [{id: 'very_intellegent', label: t('very_intellegent')}, {id: 'intellegent', label: t('intellegent')}, {id: 'standart', label: t('standart')}];
  const appearnceOptions = [{id: 'very_important', label: t('very_important')},{id: 'important', label: t('important')}, {id: 'some_importance', label: t('some_importance')}, {id: 'usually_not', label: t('not_important')}];
  const societyOptions = [{id: 'very_important', label: t('very_important')},{id: 'important', label: t('important')}, {id: 'some_importance', label: t('some_importance')}, {id: 'usually_not', label: t('not_important')}];
 
  // boys
  // const yeshivotArray = [t("chevron"), t("rab_hilel"), t("ateret"), t("beer_yaakov"), t("or_israel"), t("nahalat_afula"), t("landa"), 
  // t("mir_brachfeld"), t("volfson"), t("natahlat_haleviim"), t("kol_tora"), t("itry"), t("beth_mates"), t("soloveichik"), t("maor_hatalmud"), 
  // t("min_hahar"), t("sharey_shmuot"), t("sorozkin"), t("grodna_beer_yaakov"), t("kaplan"), t("new_kaplan"), t("ponovich"), t("yad_aharon"), 
  // t("etinger"), t("tifrach"), t("slabodka"), t("schar_sachir"), t("betifarto"), t("orchot_tora"), t("reshet_karmiel"), t("ozem"), 
  // t("elyon"), t("nahalat_binyamin"), t("givat_shaul"), t("rishon"), t("gil"), t("hadera_kiryat_sefer"), t("mir"), t("kiryat_melech"), 
  // t("kfar_hasidim"), t("or_yehuda")];
  

  const yeshivotArray = [ "or_yehuda", "or_israel", "orchot_tora", "etinger", "itry", "beer_yaakov", "beth_mates", "givat_shaul", "gil",  "grodna_beer_yaakov", "volfson",  "hevron", "hadera_kiryat_sefer",  "yad_aharon", "landa", "maor_hatalmud", "mir",  "mir_brachfeld", "min_hahar",  
  "mishcan_asher", "nahalat_binyamin", "nahlat_haleviim", "nahlat_afula",  
  "soloveichik", "sorozkin","silver", "slabodka", "ozem", "ateret", "elyon" , "ponovich", "kol_tora", "kaplan", "new_kaplan", "kfar_hasidim", "kiryat_melech", "rab_hilel","rishon", "reshet_karmiel", 
  "schar_sachir", "sharey_shmuot", "betifarto", "tifrach", "OTHER"];
  const hatmadaOptions = [{id:"all_the_time", label: t('always_learning')}, {id:"serious", label: t('serious')}, 
  {id:"sdarim", label: t('sdarim')}, {id:"sometimes", label: t('learning_sometimes')}];

  const openPrincipels = [{id: 'general_books', label: t('general_books')}, {id: 'general_music', label: t('general_music')}, 
  {id: 'watch_sports', label: t('watch_sports')}, {id: 'attend_sports', label: t('attend_sports')}, {id: 'resturants', label: t('resturants')}, 
  {id: 'fly_friends', label: t('fly_friends')}, {id: 'gym', label: t('gym')}, {id: 'mixed_shows', label: t('mixed_shows')}, {id: 'general_news', label: t('general_news')}, 
  {id: 'hotels', label: t('hotels')}, {id: 'wet_trips', label: t('wet_trips')}, {id: 'mall', label: t('mall')}, {id: 'driving_license', label: t('driving_license')}, 
  {id: 'partner_driving', label: t('partner_driving')}, {id: 'work_chiloni', label: t('work_chiloni')}];

  const closePrincipels =[ {id: 'hotels', label: t('hotels')}, {id: 'wet_trips', label: t('wet_trips')}, {id: 'mall', label: t('mall')}
 , {id: 'read_charedi_news', label: t('read_charedi_news')}, {id: 'general_radio', label: t('general_radio')}, {id: 'charedi_radio', label: t('support_radio')}, 
 {id: 'support_cosher_phone', label: t('support_cosher_phone')}, {id: 'computer', label: t('computer')}, {id: 'modern_charedi_music', label: t('modern_music')}, 
 {id: 'perfume', label: t('perfume')},  {id: 'hold_smartphone', label: t('hold_smartphone')}];
  
 const positivePrinciplesList = [{id: 'natla', label: t('natla')},  {id: 'magen_avraham', label: t('magen_avraham')}, {id: 'musar', label: t('musar_learning')}];
 
 const eyesSavingLevel = [{id:"eyes_highest", label: t('eyes_highest')}, {id:"eyes_average", label: t('eyes_average')}, {id:"eyes_low", label: t('eyes_low')}];
  
  
  // girls
  const openLevels = [{id: 'very_open', label: gender && gender == "Female" ? t('very_open_female'): t('very_open')}, {id: 'open', label: gender && gender == "Female" ? t('open_female'): t('open')}, {id: 'average', label: gender && gender == "Female" ? t('average_female'): t('average')}, {id: 'close', label: gender && gender == "Female" ? t('close_female'): t('close')}]

  const openPrincipelsFemale = [{id: 'general_books', label: t('general_books_female')}, {id: 'general_music', label: t('general_music_female')}, 
  {id: 'watch_sports', label: t('watch_sports_female')}, {id: 'attend_sports', label: t('attend_sports_female')}, {id: 'resturants', label: t('resturants_female')}, 
  {id: 'fly_friends', label: t('fly_friends_female')}, {id: 'gym', label: t('gym_female')}, {id: 'mixed_shows', label: t('mixed_shows_female')}, {id: 'general_news', label: t('general_news_female')}, 
  {id: 'hotels', label: t('hotels_female')}, {id: 'wet_trips', label: t('wet_trips_female')}, {id: 'mall', label: t('mall_female')}, {id: 'driving_license', label: t('driving_license')}, {id: 'work_chiloni', label: t('work_chiloni_female')}];

  const closePrincipelsFemale =[ {id: 'hotels', label: t('hotels_female')}, {id: 'wet_trips', label: t('wet_trips_female')}, {id: 'mall', label: t('mall_female')}
 , {id: 'read_charedi_news', label: t('read_charedi_news_female')}, {id: 'general_radio', label: t('general_radio_female')}, {id: 'charedi_radio', label: t('support_radio_female')}, 
 {id: 'support_cosher_phone', label: t('support_cosher_phone_female')}, {id: 'computer', label: t('computer_female')}, {id: 'modern_charedi_music', label: t('modern_music_female')}, 
 {id: 'perfume', label: t('perfume_female')},  {id: 'hold_smartphone', label: t('hold_smartphone_female')}];

const intenetOptions = [{id: 'no', label:  t("no_enterance")}, {id: 'work_needs', label:  t("work_needs")}, {id: 'filtered', label:  t("filtered")}, {id: 'open', label:  t("open")}];
const smartphoneOptions = [{id: 'no', label:  t("no_way")}, {id: 'filtered_work_wife', label:  t("smartphone_work_needs")}, {id: 'filtered_home', label:  t("filtered_home")}, {id: 'filtered_personal_both', label:  t("filtered_owners")}, {id: 'no_limits', label:  t("no_problem")}];
const moviesOptions =  [{id: 'not_watching', label:  t("doesnt_see")}, {id: 'now_not_future', label:  t("now_not_future")}, {id: 'now_might_future', label:  t("now_might_future")}, {id: 'now_and_future', label:  t("now_and_future")}];
const halachaOptions = [{id: 1, label: '1'}, {id: 2, label: '2'}, {id: 3, label: '3'}];

 const modestyLevels = [{id:"top_value", label: t('modesty_highest')}, {id:"modest", label: t('modesty_high')}, {id:"average", label: t('modesty_average')}, {id:"less_than_average", label: t('modesty_low')}];
 const socksType = [{id: '_70_danyer', label: t('70_danyer')}, {id: '_40_danyer', label: t('40_danyer')}, {id: 'nothing', label: t('no_specific')}];
 const rabaysLevels = [{id:"often_consulting", label: gender && gender == "Female" ? t('rabays_often_female'): t('rabays_often')}, {id:"obeys_all", label: gender && gender == "Female" ? t('rabays_always_obey_female'): t('rabays_always_obey')}, 
 {id:"obeys_high_profile", label: gender && gender == "Female" ? t('rabays_sometimes_obey_female'): t('rabays_sometimes_obey')}, {id:"independent", label: gender && gender == "Female" ? t('rabays_judge_female'): t('rabays_judge')}];
 
 const kashrutLevel = [{id: "all_mehadrin", label: t('rabanut_and_badaz')}, {id: "strong_badaz", label: t('strong_badaz')}];
 const toraSacrificeOptions = [{id:"everything", label: t('everything')}, {id:"a_lot", label: t('a_lot')}, 
 {id:"possible", label: t('possible')}, {id:"comfort", label: t('comfort')}];

 const toraPlansOptions = [{id:"always", label: t('always')}, {id:"shtetel", label: t('shtetel')}, 
 {id:"for_a_while", label: t('for_a_while')}, {id:"sometimes", label: t('sometimes')}];

 const wealthLevelOptions = [{id: 'very_high', label: t('very_high')}, {id: 'high', label: t('high')}, {id: 'standart', label: t('standart_female')}, {id: 'under_standart', label: t('under_standart')} ];
 const livingLocationTodayList = [{id: 'jerusalem', label: t('jerusalem')}, {id: 'bney_brak', label: t('bney_brak')},  {id: 'modiin_ilit', label: t('modiin_ilit')}, {id: 'elad', label: t('elad')}, {id: 'bet_shemesh', label: t('bet_shemesh')}, {id: 'beitar', label: t('beitar')}, 
 {id: 'charedi_periferia', label: t('charedi_periferia')}, {id: 'center', label: t('center_not_charedi')}, {id: 'periferia', label: t('periferia')}];
 
  const offersAreaPreferList = [{id: 'jerusalem', label: t('jerusalem')}, {id: 'bney_brak', label: t('bney_brak')}, {id: 'center_charedi', label: t('center_charedi')}, {id: 'periferia_charedi', label: t('periferia_charedi')}, 
  {id: 'not_meaningful', label: t('not_meaningful')}];

 const livingPrefer = [{id: 'no_prefer', label: t('no_prefer')}, {id: 'mixed', label: t('mixed_prefer')}, {id: 'charedi', label: t('charedi_prefer')}];
 const livignNegative = [{id: 'jerusalem', label: t('jerusalem')}, {id: 'bney_brak', label: t('bney_brak')}, {id: 'modiin_ilit', label: t('modiin_ilit')}, {id: 'elad', label: t('elad')}, {id: 'bet_shemesh', label: t('bet_shemesh')}, {id: 'beitar', label: t('beitar')}, {id: 'south_area', label: t('south_area')}
 ,{id: 'north_area', label: t('north_area')}, {id: 'centeral', label: t('centeral')}, {id: 'no', label: t('no_negs')}];
 const livingObligation = [{id: 'jerusalem', label: t('jerusalem')}, {id: 'bney_brak', label: t('bney_brak')}, {id: 'modiin_ilit', label: t('modiin_ilit')}, {id: 'elad', label: t('elad')}, {id: 'bet_shemesh', label: t('bet_shemesh')}, {id: 'beitar', label: t('beitar')}, {id: 'south_area', label: t('south_area')}
 ,{id: 'north_area', label: t('north_area')}, {id: 'centeral', label: t('centeral')}, {id: 'no', label: t('no')}];

 const yesNoOptioms = [{id: 'yes', label: t('yes')}, {id: 'no', label: t('no')}];
 const familyDefintion = [{id: 'avrech', label: t('avrech')}, {id: 'jobs', label: t('jobs')}, {id: 'workers', label: t('workers')},  {id: 'chozrim_bitshuva', label: t('chozrim_bitshuva')}, {id: 'undefined', label: t('undefined')} ];
 const familyStyle =  [{id: 'regular', label: t('regular')}, {id: 'important', label: t('important')}, {id: 'yichus', label: t('yichus')}];
 const moneyGivesOptions = [{id: 'depends', label: t('depends')}, {id: 'same_as', label: t('same_as')}, 
 {id: 'not_interested_to_mention', label: t('not_interested_to_mention')}, {id: 'specific_amount', label: t('specific_schum')}];

 const headCoverOptions = [{id: 'wig', label: t('wig')}, {id: 'mitpachat', label: t('mitpachat')}, {id: 'wig_or_mitpachat', label: t('wig_or_mitpachat')}];
 const siblingsSchoolsOptions = [{id: 'ashkenazi_schools', label: t('ashkenazi_schools')}, {id: 'sfaradi_schools', label: t('sfaradi_schools')}]
 const abroadLocalOptions = [{id:'from_abroad',  label: t("origin_abroad")}, {id:'parents_from_abroad',  label: t("parents_origin_abroad")}, {id:'agree_parents_abroad',  label: t("agree_parents_abroad")}, {id:'agree_abroad',  label: t("agree_abroad")}, {id:'just_israel',  label: t("just_israel")}];
 
 const moneyDemandsOptions  = [{id: 'flexable_low', label: t("flexable_low")}, {id: 'flexable_high', label: t("flexable_high")}, {id: 'not_specific_demands', label: t("not_specific_demands")}];

 const nations = [{id: 'american', label: t('american')}, {id: 'european', label: t('european')}, {id: 'south_american', label: t('south_american')}, {id: 'other', label: t('other')}];
 const nationsAgreement = [{id: 'american', label: t('american')}, {id: 'european', label: t('european')}, {id: 'south_american', label: t('south_american')}, {id: 'israel', label: t('israel')}];

  const politicRelationList = [{id: 'tree', label: t('tree')}, {id: 'gimel', label: t('gimel')}, {id: 'shas', label: t('shas')}, {id: 'no', label: t('no')}]
const shulOptions = [{id: 'ashkenazi_shul', label: t('ashkenazi_shul')}, {id: 'sfaradi_shul', label: t('sfaradi_shul')}];

const openLevelCondition = () => {
  return openLevel == "very_open" || openLevel == "open" || openLevel == "average";
}

const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
}; 

const handleValidation = () => {
  let tempValidationErrors = []
  let valid = true;
  if (!firstName || !firstName.length > 0) {
    tempValidationErrors.push("firstName");
    setValidationErrors(validationErrors => ({...validationErrors, ["firstName"]: t("empty_field_error")}));
    valid = false;
  }
  if (!lastName  || !lastName.length > 0) {
    tempValidationErrors.push("lastName");
    setValidationErrors(validationErrors => ({...validationErrors, ["lastName"]: t("empty_field_error")}));
    valid = false;
  }
  if (!phone || phone.length < 9) {
    tempValidationErrors.push("phone");
    setValidationErrors(validationErrors => ({...validationErrors, ["phone"]: t("too_short_error")}));
    valid = false;
  }
  if (!email || !email.length > 0) {
    tempValidationErrors.push("email");
    setValidationErrors(validationErrors => ({...validationErrors, ["email"]: t("empty_field_error")}));
    valid = false;
  }

  if (!validateEmail(email)) {
    tempValidationErrors.push("email");
    setValidationErrors(validationErrors => ({...validationErrors, ["email"]: t("wrong_email_format")}));
    valid = false;
  }

  if (!gender || !gender.length > 0) {
    tempValidationErrors.push("gender");
    setValidationErrors(validationErrors => ({...validationErrors, ["gender"]: t("empty_field_error")}));
    valid = false;
  }
  if (!birthYear || !birthYear > 0) {
    tempValidationErrors.push("birthYear");
    setValidationErrors(validationErrors => ({...validationErrors, ["birthYear"]: t("empty_field_error")}));
    valid = false;
  }
  if (!birthMonth || !birthMonth > 0) {
    tempValidationErrors.push("birthMonth");
    setValidationErrors(validationErrors => ({...validationErrors, ["birthMonth"]: t("empty_field_error")}));
    valid = false;
  }
  // TODO: think about years up & down validation
  if (!fatherRace || !fatherRace.length > 0) {
    tempValidationErrors.push("fatherRace");
    setValidationErrors(validationErrors => ({...validationErrors, ["fatherRace"]: t("empty_field_error")}));
    valid = false;
  }
  if (!motherRace || !motherRace.length > 0) {
    tempValidationErrors.push("motherRace");
    setValidationErrors(validationErrors => ({...validationErrors, ["motherRace"]: t("empty_field_error")}));
    valid = false;
  }
  if (!fatherRaceAgreement || !fatherRaceAgreement.length > 0) {
    tempValidationErrors.push("fatherRaceAgreement");
    setValidationErrors(validationErrors => ({...validationErrors, ["fatherRaceAgreement"]: t("empty_field_error")}));
    valid = false;
  }
  if (!motherRaceAgreement || !motherRaceAgreement.length > 0) {
    tempValidationErrors.push("motherRaceAgreement");
    setValidationErrors(validationErrors => ({...validationErrors, ["motherRaceAgreement"]: t("empty_field_error")}));
    valid = false;
  }
  if (!height || height == undefined || height == '') {
    console.log('height: ', height);
    tempValidationErrors.push("height");
    setValidationErrors(validationErrors => ({...validationErrors, ["height"]: t("empty_field_error")}));
    valid = false;
  }
  if (!fat && !fat.length > 0) {
    tempValidationErrors.push("fat");
    setValidationErrors(validationErrors => ({...validationErrors, ["fat"]: t("empty_field_error")}));
    valid = false;
  }
  if (!fatherName && !fatherName.length > 0) {
    tempValidationErrors.push("fatherName");
    setValidationErrors(validationErrors => ({...validationErrors, ["fatherName"]: t("empty_field_error")}));
    valid = false;
  }
  if (!motherName && !motherName.length > 0) {
    tempValidationErrors.push("motherName");
    setValidationErrors(validationErrors => ({...validationErrors, ["motherName"]: t("empty_field_error")}));
    valid = false;
  }
  if (!smoker && !smoker.length > 0) {
    tempValidationErrors.push("smoke");
    setValidationErrors(validationErrors => ({...validationErrors, ["smoke"]: t("empty_field_error")}));
    valid = false;
  }
  if (!fromAbroad &&!fromAbroad.length > 0) {
    tempValidationErrors.push("fromAbroad");
    setValidationErrors(validationErrors => ({...validationErrors, ["fromAbroad"]: t("empty_field_error")}));
    valid = false;
  }
  if ((fromAbroad == "from_abroad" || fromAbroad == "parents_from_abroad" ) && !nation.length > 0) {
    tempValidationErrors.push("nation");
    setValidationErrors(validationErrors => ({...validationErrors, ["nation"]: t("empty_field_error")}));
    valid = false;
  }
  if (fromAbroad == "from_abroad" && !nationAgreement.length > 0) {
    tempValidationErrors.push("nationAgreement");
    setValidationErrors(validationErrors => ({...validationErrors, ["nationAgreement"]: t("empty_field_error")}));
    valid = false;
  }
  if (!school || !school.length > 0) {
    tempValidationErrors.push("school");
    setValidationErrors(validationErrors => ({...validationErrors, ["school"]: t("empty_field_error")}));
    valid = false;
  }
  // if (!chasid.length > 0) {
  //   tempValidationErrors.push("chasid");
  //   setValidationErrors(validationErrors => ({...validationErrors, ["chasid"]: t("empty_field_error")}));
  //   valid = false;
  // }
  if (gender == "Female" && (!profession || !profession.length > 0)) {
    tempValidationErrors.push("profession");
    setValidationErrors(validationErrors => ({...validationErrors, ["profession"]: t("empty_field_error")}));
    valid = false;
  }
  if (gender == "Female" && (!professionStatus || !professionStatus.length > 0)) {
    tempValidationErrors.push("professionStatus");
    setValidationErrors(validationErrors => ({...validationErrors, ["professionStatus"]: t("empty_field_error")}));
    valid = false;
  }
  if (gender == "Male" && (!professionsAgreement || !professionsAgreement.length > 0)) {
    tempValidationErrors.push("professionAgreement");
    setValidationErrors(validationErrors => ({...validationErrors, ["professionAgreement"]: t("empty_field_error")}));
    valid = false;
  }
  if (!openLevel || !openLevel.length > 0) {
    tempValidationErrors.push("openLevel");
    setValidationErrors(validationErrors => ({...validationErrors, ["openLevel"]: t("empty_field_error")}));
    valid = false;
  }
  if (!halacha || !halacha.length > 0) {
    tempValidationErrors.push("halacha");
    setValidationErrors(validationErrors => ({...validationErrors, ["halacha"]: t("empty_field_error")}));
    valid = false;
  }
  // if (gender == "Male" && !halacaHakpada.length > 0) {
  //   setValidationErrors(validationErrors => ({...validationErrors, ["halachahakpada"]: t("empty_field_error")}));
  //   valid = false;
  // }
  if (gender == "Male" && (!bread || !bread.length > 0)) {
    tempValidationErrors.push("bread");
    setValidationErrors(validationErrors => ({...validationErrors, ["bread"]: t("empty_field_error")}));
    valid = false;
  }
  if (openLevelCondition() && (!internet || !internet.length > 0)) {
    tempValidationErrors.push("internet");
    setValidationErrors(validationErrors => ({...validationErrors, ["internet"]: t("empty_field_error")}));
    valid = false;
  }
  if (openLevelCondition() && (!movies || !movies.length > 0)) {
    tempValidationErrors.push("movies");
    setValidationErrors(validationErrors => ({...validationErrors, ["movies"]: t("empty_field_error")}));
    valid = false;
  }
  if (openLevelCondition() && (!smartphone || !smartphone.length > 0)) {
    tempValidationErrors.push("smartphone");
    setValidationErrors(validationErrors => ({...validationErrors, ["smartphone"]: t("empty_field_error")}));
    valid = false;
  }
  if (!modestyLevel || !modestyLevel.length > 0) {
    tempValidationErrors.push("modestyLevel");
    setValidationErrors(validationErrors => ({...validationErrors, ["modestyLevel"]: t("empty_field_error")}));
    valid = false;
  }
  if (gender == "Male" && openLevel != "very_open" && (!eyesSaving || !eyesSaving.length > 0)) {
    tempValidationErrors.push("eyesSaving");
    setValidationErrors(validationErrors => ({...validationErrors, ["eyesSaving"]: t("empty_field_error")}));
    valid = false;
  }
  if (!rabays || !rabays.length > 0) {
    tempValidationErrors.push("rabays");
    setValidationErrors(validationErrors => ({...validationErrors, ["rabays"]: t("empty_field_error")}));
    valid = false;
  }
  if (!kashrut || !kashrut.length > 0) {
    tempValidationErrors.push("kashrut");
    setValidationErrors(validationErrors => ({...validationErrors, ["kashrut"]: t("empty_field_error")}));
    valid = false;
  }
  if (gender == "Male" && (!toraPlans || !toraPlans.length > 0)) {
    tempValidationErrors.push("toraPlans");
    setValidationErrors(validationErrors => ({...validationErrors, ["toraPlans"]: t("empty_field_error")}));
    valid = false;
  }
  if (gender == "Female" && (!toraPlansAgreement || !toraPlansAgreement.length > 0)) {
    tempValidationErrors.push("toraPlansAgreement");
    setValidationErrors(validationErrors => ({...validationErrors, ["toraPlansAgreement"]: t("empty_field_error")}));
    valid = false;
  }
  if (!toraSacrifice || !toraSacrifice.length > 0) {
    tempValidationErrors.push("toraSacrifice");
    setValidationErrors(validationErrors => ({...validationErrors, ["toraSacrifice"]: t("empty_field_error")}));
    valid = false;
  }
  if (gender == "Male" && (!toraHatmada || !toraHatmada.length > 0)) {
    tempValidationErrors.push("toraHatmada");
    setValidationErrors(validationErrors => ({...validationErrors, ["toraHatmada"]: t("empty_field_error")}));
    valid = false;
  }
  if (!interestsList || !interestsList.length > 0) {
    tempValidationErrors.push("interests");
    setValidationErrors(validationErrors => ({...validationErrors, ["interests"]: t("empty_field_error")}));
    valid = false;
  }
  if (!interestsList || interestsList.length > 4) {
    tempValidationErrors.push("interests");
    setValidationErrors(validationErrors => ({...validationErrors, ["interests"]: t("too_many_options_selected")}));
    valid = false;
  }
  if (!hobbiesList || !hobbiesList.length > 0) {
    tempValidationErrors.push("hobbies");
    setValidationErrors(validationErrors => ({...validationErrors, ["hobbies"]: t("empty_field_error")}));
    valid = false;
  }
  if (!smartness || !smartness.length > 0) {
    tempValidationErrors.push("smartness");
    setValidationErrors(validationErrors => ({...validationErrors, ["smartness"]: t("empty_field_error")}));
    valid = false;
  }
  if (!intellegnce || !intellegnce.length > 0) {
    tempValidationErrors.push("intellegnce");
    setValidationErrors(validationErrors => ({...validationErrors, ["intellegnce"]: t("empty_field_error")}));
    valid = false;
  }
  if (!appearnce || !appearnce.length > 0) {
    tempValidationErrors.push("appearnce");
    setValidationErrors(validationErrors => ({...validationErrors, ["appearnce"]: t("empty_field_error")}));
    valid = false;
  }
  if (!societyInfluece || !societyInfluece.length > 0) {
    tempValidationErrors.push("societyInfluence");
    setValidationErrors(validationErrors => ({...validationErrors, ["societyInfluence"]: t("empty_field_error")}));
    valid = false;
  }
  if (!personalityTestList || !personalityTestList.includes(true)) {
    tempValidationErrors.push("personalityTestList");
    setValidationErrors(validationErrors => ({...validationErrors, ["personalityTestList"]: t("non_options_checked")}));
    valid = false;
  }
  if (!lifestyleLevelToday || !lifestyleLevelToday.length > 0) {
    tempValidationErrors.push("lifestyleLevelToday");
    setValidationErrors(validationErrors => ({...validationErrors, ["lifestyleLevelToday"]: t("empty_field_error")}));
    valid = false;
  }
  if (!lifestyleLevelFuture || !lifestyleLevelFuture.length > 0) {
    tempValidationErrors.push("lifestyleLevelFuture");
    setValidationErrors(validationErrors => ({...validationErrors, ["lifestyleLevelFuture"]: t("empty_field_error")}));
    valid = false;
  }
  if (!livingLocationPrefer || !livingLocationPrefer.length > 0) {
    tempValidationErrors.push("livingLocationPrefer");
    setValidationErrors(validationErrors => ({...validationErrors, ["livingLocationPrefer"]: t("empty_field_error")}));
    valid = false;
  }
  if (!livingNegs || !livingNegs.length > 0) {
    tempValidationErrors.push("livingNegs");
    setValidationErrors(validationErrors => ({...validationErrors, ["livingNegs"]: t("empty_field_error")}));
    valid = false;
  }
  if (!livingObligation || !livingObligation.length > 0) {
    tempValidationErrors.push("livingObligation");
    setValidationErrors(validationErrors => ({...validationErrors, ["livingObligation"]: t("empty_field_error")}));
    valid = false;
  }
  if (!familyDefinition || !familyDefinition.length > 0) {
    tempValidationErrors.push("familyDefinition");
    setValidationErrors(validationErrors => ({...validationErrors, ["familyDefinition"]: t("empty_field_error")}));
    valid = false;
  }
  if (!familyImportance || !familyImportance.length > 0) {
    tempValidationErrors.push("familyStyle");
    setValidationErrors(validationErrors => ({...validationErrors, ["familyStyle"]: t("empty_field_error")}));
    valid = false;
  }
  if (!familySize || !familySize.length > 0) {
    tempValidationErrors.push("familySize");
    setValidationErrors(validationErrors => ({...validationErrors, ["familySize"]: t("empty_field_error")}));
    valid = false;
  }
  if (!fatherWork || !fatherWork.length > 0) {
    tempValidationErrors.push("fatherWork");
    setValidationErrors(validationErrors => ({...validationErrors, ["fatherWork"]: t("empty_field_error")}));
    valid = false;
  }
  if (!motherWork || !motherWork.length > 0) {
    tempValidationErrors.push("motherWork");
    setValidationErrors(validationErrors => ({...validationErrors, ["motherWork"]: t("empty_field_error")}));
    valid = false;
  }
  if (gender == "Male" && (!moneyDemands || !moneyDemands.length > 0)) {
    tempValidationErrors.push("moneyDemands");
    setValidationErrors(validationErrors => ({...validationErrors, ["moneyDemands"]: t("empty_field_error")}));
    valid = false;
  }
  if ((moneyDemands == "flexable_high"  ||  moneyDemands == "flexable_low") && !moneyDemandsNumber > 0) {
    tempValidationErrors.push("moneyDemandsNumber");
    setValidationErrors(validationErrors => ({...validationErrors, ["moneyDemandsNumber"]: t("empty_field_error")}));
    valid = false;
  }
  if (!moneyGives || !moneyGives.length > 0) {
    tempValidationErrors.push("moneyGives");
    setValidationErrors(validationErrors => ({...validationErrors, ["moneyGives"]: t("empty_field_error")}));
    valid = false;
  }
  if (gender == "Male" && (!marketTime || !marketTime.length > 0)) {
    tempValidationErrors.push("marketTime");
    setValidationErrors(validationErrors => ({...validationErrors, ["marketTime"]: t("empty_field_error")}));
    valid = false;
  }
  if (cancelShiduch == undefined) {
    tempValidationErrors.push("cancelShiduch");
    setValidationErrors(validationErrors => ({...validationErrors, ["cancelShiduch"]: t("empty_field_error")}));
    valid = false;
  }
  if (devorsedParents == undefined) {
    tempValidationErrors.push("devorsedParents");
    setValidationErrors(validationErrors => ({...validationErrors, ["devorsedParents"]: t("empty_field_error")}));
    valid = false;
  }
  if (smallFamilyBother == undefined) {
    tempValidationErrors.push("smallFamilyBother");
    setValidationErrors(validationErrors => ({...validationErrors, ["smallFamilyBother"]: t("empty_field_error")}));
    valid = false;
  }
  if (workersBrothers == undefined) {
    tempValidationErrors.push("workersBrothers");
    setValidationErrors(validationErrors => ({...validationErrors, ["workersBrothers"]: t("empty_field_error")}));
    valid = false;
  }
  if (hasNoshrim == undefined) {
    tempValidationErrors.push("hasNoshrim");
    setValidationErrors(validationErrors => ({...validationErrors, ["hasNoshrim"]: t("empty_field_error")}));
    valid = false;
  }
  if (isFilePicked && selectedFile.size > 1000000) {
    tempValidationErrors.push("cvFile");
    setValidationErrors(validationErrors => ({...validationErrors, ["cvFile"]: t("file_too_big_error")}));
    valid = false;
  }
  if (shadchanCode == undefined || shadchanCode >= 10000) {
    console.log('Shadchan code: ', shadchanCode)
    tempValidationErrors.push("shadchanCode");
    setValidationErrors(validationErrors => ({...validationErrors, ["shadchanCode"]: t("empty_field_error")}));
    valid = false;
  }
  return tempValidationErrors
}

const handleErrors = (id) => {
  //console.log("inside handle error functions, id: ", id);
  setValidationErrors(validationErrors => ({...validationErrors, [id]: ''}));
}

const fileUpload = (event) => {
  //console.log('event file: ', event.target.files[0]);
  setSelectedFile(event.target.files[0]);
	setIsFilePicked(true);
  //console.log('selected file: ', selectedFile);
}


 const handleSubmit = async () => {
  // validations over all the fields
  let tempValidationErrors = handleValidation();
  if (tempValidationErrors.length > 0) { // invalid
    //console.log('validation errors: ', validationErrors);
    setSavedButtonDisabled(false);
    // let index = 0;
    // for (let value of Object.values(validationErrors)) {
    //   if (value != '') {
    //      break;
    //   }
    //   index++;
    // }
    // console.log('index: ', index);
    // console.log(`--- validation errors: ${Object.keys(validationErrors)[index]}`)
    // console.log(`--- validation errors: ${Object.values(validationErrors)[index]}`)
    console.log(`---- validation errors: ${tempValidationErrors[0]}`)
    // return;
    const input = document.querySelector(
      `[name=${tempValidationErrors[0]}]`
    );

    if (input) {
      input.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
      });
    }
    return;
  }

  // change all profession agreement from true to flase
  let professionsAgreementOpposite = []
  if (professionsAgreement) {
    console.log('profession list: ', professionsList)
    for (let i = 0; i< professionsList.length; i++) {
      if (!professionsAgreement.includes(professionsList[i].id)) {
          console.log('profession id to add: ', professionsList[i].id)
          professionsAgreementOpposite.push(professionsList[i].id)
      }
    }
  }
  console.log('Professions opposite list: ', professionsAgreementOpposite)
 
  let profile = {
    first_name: firstName, 
    last_name: lastName,
    email: email,
    phone: phone, 
    gender: gender.toUpperCase(), 
    birth_month: birthMonth,
    birth_year: birthYear,
    years_up: yearsUp ? parseInt(yearsUp): 0,
    years_down: yearsDown ? parseInt(yearsDown) : 0,
    father_race: fatherRace.toUpperCase(),
    mother_race: motherRace.toUpperCase(),
    father_race_agreement: fatherRaceAgreement ? fatherRaceAgreement.map( x => x.toUpperCase()) : null,
    mother_race_agreement: motherRaceAgreement ? motherRaceAgreement.map( x => x.toUpperCase()): null,
    smoker: smoker.toUpperCase(), 
    chasid: Boolean(chasid), 
    fat: fat.toUpperCase(), 
    skin_color: skinColor ? skinColor.toUpperCase(): null, 
    dark_bother: Boolean(darkSkinBother),
    height: height, 
    height_agreement_less: Boolean(heightAgreementLess),
    height_agreement_same: Boolean(heightAgreementSame),
    father_name: fatherName, 
    mother_name: motherName, 
    from_abroad: fromAbroad != "" ? fromAbroad.toUpperCase() : null, 
    nation: nation != "" ? nation.toUpperCase() : null, 
    nation_agreement: nationAgreement ? nationAgreement.map(x=> x.toUpperCase()) : null, 
    school: school.toUpperCase(), 
    profession: profession? profession.toUpperCase() : null,
    professions_agreement: professionsAgreement ? professionsAgreementOpposite.map(x=> x.toUpperCase()) : null, 
    profession_status: professionStatus ? professionStatus.toUpperCase() : null,
    open_level: openLevel.toUpperCase(), 
    halacha: parseInt(halacha),
    halacha_hakpada: halacaHakpada ? halacaHakpada.map(x=> x.toUpperCase()) : null, 
    bread: bread ? bread.toUpperCase(): null, 
    internet: internet != "" ? internet.toUpperCase() : null, 
    movies: movies != "" ? movies.toUpperCase(): null, 
    smartphone: smartphone != "" ? smartphone.toUpperCase(): null,
    principles: principles ? principles.map(x => x.toUpperCase()) : null,
    positive_principles: positivePrinciples ? positivePrinciples.map( x=> x.toUpperCase()) : null, 
    modesty_level: modestyLevel != "" ? modestyLevel.toUpperCase() : null,
    socks: socks != "" ? socks.toUpperCase() : null, 
    eyes_saving: eyesSaving != "" ? eyesSaving.toUpperCase() : 'EYES_LOW',
    rabays: rabays != "" ? rabays.toUpperCase() : null, 
    kashrut: kashrut != "" ? kashrut.toUpperCase(): null,
    tora_plans: toraPlans != "" ? toraPlans.toUpperCase() : null, 
    tora_plans_agreement: toraPlansAgreement ? toraPlansAgreement.map( x => x.toUpperCase()) : null,
    tora_sacrifice: toraSacrifice != ""  ? toraSacrifice.toUpperCase() : null, 
    tora_hatmada: toraHatmada != "" ? toraHatmada.toUpperCase() : null,  
    interests: interestsList ? interestsList.map( x=> x.toUpperCase()): null, 
    hobbies: hobbiesList ? hobbiesList.map( x=> x.toUpperCase()) :null, 
    smartness: smartness != "" ? smartness.toUpperCase(): null, 
    intellegnce: intellegnce != "" ? intellegnce.toUpperCase() : null, 
    appearnce: appearnce != "" ? appearnce.toUpperCase() : null, 
    society_influence: societyInfluece != "" ? societyInfluece.toUpperCase() : null, 
    lifestyle_level_today: lifestyleLevelToday != "" ? lifestyleLevelToday.toUpperCase() : null, 
    lifestyle_level_future: lifestyleLevelFuture != "" ? lifestyleLevelFuture.toUpperCase() : null, 
    living_location_prefer: livingLocationPrefer != "" ? livingLocationPrefer.toUpperCase() : null, 
    living_location_today: livingLocationToday != "" ? livingLocationToday.toUpperCase(): null,
    offers_area_prefer: offersAreaPrefer != "" ? offersAreaPrefer.toUpperCase(): null,
    living_negs: livingNegs ? livingNegs.map( x => x.toUpperCase()) : null, 
    living_obligation: locationObligation != "" ? locationObligation.toUpperCase() : null, 
    abroad_plans: Boolean(abroadPlans), 
    family_importance: familyImportance != "" ? familyImportance.toUpperCase() : null, 
    family_definition: familyDefinition != "" ? familyDefinition.toUpperCase() :null, 
    simple_family_bother: Boolean(simpleFamilyBother), 
    yichus_matters: Boolean(yichusMatters), 
    family_size: familySize ? familySize.toUpperCase() : null, 
    small_family_bother: Boolean(smallFamilyBother), 
    has_noshrim: Boolean(hasNoshrim), 
    workers_brothers: Boolean(workersBrothers), 
    father_work: fatherWork? fatherWork.toUpperCase() : null, 
    mother_work: motherWork? motherWork.toUpperCase() : null, 
    money_gives: moneyGives != "" ? moneyGives.toUpperCase() : null, 
    money_gives_number: moneyGivesNumber ? parseInt(moneyGivesNumber) : null,
    money_demands_number: moneyDemandsNumber ? parseInt(moneyDemandsNumber) : null, 
    money_demands_string: moneyDemands != "" ? moneyDemands.toUpperCase() :null, 
    persoality_test: personalityTestList,
    cancel_shiduch: Boolean(cancelShiduch),
    devorsed_parents: Boolean(devorsedParents),
    politic_relation: politicRelation ? politicRelation.toUpperCase() : null, 
    head_cover: headCover ? headCover.toUpperCase(): null,
    siblings_schools : siblingsSchools ? siblingsSchools.toUpperCase(): null,
    school_type: schoolType ? schoolType.toUpperCase(): null,
    talit_pray: Boolean(talitPray),
    shul: shul ? shul.toUpperCase(): null, 
    market_time: marketTime ? marketTime.toUpperCase(): null, 
    about_me: aboutMe,
    shadchan_code: Number(shadchanCode),
  }
  //console.log('Profile to send: ', profile);
  
  try {
    var response; 
    if (isFilePicked) {
      response = await sendCVFile(JSON.stringify(profile));
    } else {
      const url = "/user/profile/add";
      response = await sendPostRequest(url, 'POST', JSON.stringify(profile));
    }
    // console.log('Response: ', response);
    if (response.status && response.status == 200) {
      let path = '/user'; 
      localStorage.setItem('user_state', 'PROFILED')
      history.push(path); 
    }
  } catch(err) {
      console.log("Error adding query:  ", err);
      if (err.response.status == 401 || err.response.status == 403) {
        // invalid token/missing token, go to login page
        setSavedButtonDisabled(false);
        // message to user: looks like the session was expired, please relogin
        alert(t('session_expired_message'));
        let path = '/login';
        history.push(path);

      } else {
        // internal error/400 error
        // message to user: Something went wrong, we're sorry please try again
        alert(t('internal_error'));
        setSavedButtonDisabled(false);
      }
  }
  
}

async function sendCVFile(profile) {
  if (isFilePicked) {
    //console.log("Sending an upload file request");
    const formData = new FormData();
      
        // Update the formData object
    formData.append(
        "cv_file_name", selectedFile.name);

    formData.append("file", selectedFile);
    formData.append("profile", profile);
        
    const url = "/user/profile/add/cv_file";
   
    let response = await sendFormDataRequest(url, formData);
    //console.log('[sendCVFile] response: ', response);
    return response;
  }
}
    
  return (
    <>
     
    {/* bg-blueGray-100  */}
        <div className="px-6">
            <div className="flex flex-wrap justify-center">
              <div className="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">

              
              <div className="text-center mt-12">
              <h3 className="text-5xl font-semibold font-queryFont4 leading-normal mb-2 text-blueGray-700 mb-2"    style={{ fontFamily: "'Bellefair', serif" }}>
                
                {t('query_title')}
              </h3>
              <div className="text-m leading-normal mt-0 mb-2 text-blueGray-400 font-queryFont3 font-bold uppercase">
                {/* <i className="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"></i>{" "} */}
                {t('query_page_title_2')}
              </div>
              </div>
            </div>
          </div>
        <div className="mt-10 py-10 border-t border-blueGray-200 text-center bg-blueGray-50">
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase text-right font-queryFont2">
            {t("user_info_title")}
            </h6>
            <div className="flex flex-wrap font-queryFont3 text-xs1">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs1 font-bold mb-2 text-right"
                  >
                      {t("first_name_query")}
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 text-right placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                   defaultValue={firstName}
                   id="firstName"
                   name="firstName"
                   onChange={(e) => {
                    setFirstName(e.target.value); 
                    handleErrors(e.target.id);}}
                  />
                  <span className="block text-right text-xs mb-2 py-2 text-red-500" >{validationErrors["firstName"]}</span>
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-right"
                  >
                    {t("last_name_query")}
                  </label>
                  <input
                  id="lastName"
                  name="lastName"
                  type="text"
                  className="border-0 px-3 py-3 text-right placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  defaultValue={lastName}
                  onChange={(e) => {setLastName(e.target.value);  handleErrors(e.target.id);}}
                  />
                  <span className="block text-right text-xs mb-2 py-2 text-red-500" >{validationErrors["lastName"]}</span>
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-right"
                  >
                   {t("email_query")}
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="border-0 px-3 py-3 text-right placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                   defaultValue={email}
                  onChange={(e) => {setEmail(e.target.value); handleErrors(e.target.id)}}
                  />
                   <span className="block text-right text-xs mb-2 py-2 text-red-500" >{validationErrors["email"]}</span>
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-right"
                  >
                    {t("phone_query")}
                   
                  </label>
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    className="border-0 px-3 py-3 text-right placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                   defaultValue={phone}
                   onChange={(e) => {setPhone(e.target.value); handleErrors(e.target.id)}}
                  />
                   <span className="block text-right text-xs mb-2 py-2 text-red-500" >{validationErrors["phone"]}</span>
                </div>
              </div>
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  {/* <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-right"
                  >
                    {t("gender_query")}
                   
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 text-right placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    defaultValue="Female"
                  /> */}
                     <div className="relative flex min-w-0   mb-0 justify-end">
                      
                     <label className="uppercase text-blueGray-600 text-xs font-bold px-2">
                          {t("gender_male")}
                          </label>    
                          <input className="placeholder-blueGray-300 text-blueGray-600"
                            type="radio"
                            value="Male"
                            id="gender"
                            name="gender"
                            checked={gender!= undefined && gender === "Male"}
                            onChange={(e) => {setGender(e.target.value); handleErrors(e.target.id)}}
                          />
                        
                      </div>
                      <div className="radio relative flex mb-0 justify-end">
                      <label className="flex block uppercase text-blueGray-600 text-xs font-bold mb-2 text-right px-2 justify-end">
                        {t("gender_female")}
                        </label>
                          <input className="placeholder-blueGray-300 text-blueGray-600"
                            type="radio"
                            value="Female"
                            id="gender"
                            name="gender"
                            checked={gender!= undefined && gender === "Female"}
                            onChange={(e) => {setGender(e.target.value); handleErrors(e.target.id)}}
                          />
                       
                      </div>
                      <span className="block text-right text-xs mb-2 py-2 text-red-500" >{validationErrors["gender"]}</span>

                </div>
              </div>
            </div>
                
    
            {/* {gender == "Male" ? (<MaleQuery/>): (gender == "Female" ? (<FemaleQuery/>): null)} */}
          


    <div>
     <hr className="mt-6 border-b-1 border-blueGray-300" />

      <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase justify-end  font-queryFont2 text-right">
        {t("general_info_query")}
      </h6>
        <div className="flex flex-wrap font-queryFont3">
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-right"
                  >
                   {t("birth_date_query")}
                  </label>
                  
                
                  <div className="flex flex-row-reverse flex-wrap">
                    <div className="w-full flex-row-reverse lg:w-4/12 px-1">
                      <div className="relative mb-1 text-right justify-end ">
                        <SimpleDropDown maxValue="2005" minValue="1980" selectedValue={birthYear} className="text-xs2 text-right" name="birthYear" id="birthYear" onChange={setBirthYear} handleErrors={handleErrors}/>
              
                      </div>
                      <span className="block text-right text-xs mb-2 py-2 text-red-500" >{validationErrors["birthYear"]}</span>
                    </div>
                  <div className="w-full flex-row-reverse lg:w-4/12 px-1">
                    <div className="relative  mb-1 text-right justify-end ">
                      <SimpleDropDown selectedValue={birthMonth} maxValue="12" minValue="1" className="text-xs2 text-right" name="birthMonth" id="birthMonth" onChange={setBirthMonth} handleErrors={handleErrors}/>     
                  </div>
                  <span className="block text-right text-xs mb-2 py-2 text-red-500" >{validationErrors["birthMonth"]}</span>
                </div>
              </div>
              </div>
              </div>

              <div className="w-full flex flex-row-reverse px-4 mb-6">
              <div className="relative lg:w-4/12  mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-right"
                  >
                  {t("match_range_age_up")}
                  </label>
                  

                <div className="relative mb-3 text-right text-xs">
                <SimpleDropDown maxValue="10" minValue="0" selectedValue={yearsUp} className="text-xs2 text-right" onChange={setYearsUp} handleErrors={handleErrors}/>
                </div>
                {/* <span className="block text-right text-xs mb-2 py-2 text-red-500" >{validationErrors["fatherRace"]}</span>
                 */}
              </div>
                <div className="relative lg:w-4/12  mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-right"
                  >
                  {t("match_range_age_down")}
                  </label>
                  

                <div className="relative mb-1 text-right text-xs">
                <SimpleDropDown maxValue="0" minValue="-10" selectedValue={yearsDown} className="text-xs2 text-right"   onChange={setYearsDown} handleErrors={handleErrors}/>     
                </div>
                {/* <span className="block text-right text-xs mb-2 py-2 text-red-500" >{validationErrors["motherRace"]}</span> */}
              </div>
            </div>


    
            <div className="w-full flex flex-row-reverse px-4 mb-6">
              <div className="relative lg:w-4/12  mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-right"
                  >
                  {t("race_father")}
                  </label>
                  

                <div className="relative mb-3 text-right text-xs">
                  <SimpleDropDown values={raceArray} selectedValue={fatherRace} useTranslator={true} className="text-xs2 text-right justify-start float-right" id="fatherRace" name="fatherRace" onChange={setFatherRace} handleErrors={handleErrors}/>
                </div>
                <span className="block text-right text-xs mb-2 py-2 text-red-500" >{validationErrors["fatherRace"]}</span>
                
              </div>
                <div className="relative lg:w-4/12  mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-right"
                  >
                  {t("race_mother")}
                  </label>
                  

                <div className="relative mb-1 text-right text-xs">
                  <SimpleDropDown values={raceArray} selectedValue={motherRace} useTranslator={true} className="text-xs2 text-right justify-start float-right" id="motherRace" name="motherRace" onChange={setMotherRace} handleErrors={handleErrors}/>
                </div>
                <span className="block text-right text-xs mb-2 py-2 text-red-500" >{validationErrors["motherRace"]}</span>
              </div>
            </div>
              

            <div className="w-full flex flex-row-reverse px-4 mb-3">
              <div className="relative lg:w-6/12 px-4 mb-3">
                <div className="relative">
                <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-right"
                    >
                   {gender && gender=="Female" ? t("race_father_agreement_female"): t("race_father_agreement")}
                    </label>  
                <CheckBoxList value={fatherRaceAgreement} onChange={setFatherRaceAgreement} id="fatherRaceAgreement" name="fatherRaceAgreement" values={raceAgreementArray} handleErrors={handleErrors} validationErrors={validationErrors}/>
                </div>
              </div>
              
              <div className="lg:w-3/12 px-4 mb-3">
                <div className="relative">
                <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-right"
                    >
                   {t("race_mother_agreement")}
                    </label>  
                <CheckBoxList onChange={setMotherRaceAgreement} values={raceAgreementArray} id="motherRaceAgreement" name="motherRaceAgreement" value={motherRaceAgreement} handleErrors={handleErrors} validationErrors={validationErrors}/>
                </div>
            </div> 
            </div>

            <div className="w-full flex flex-row-reverse px-4 mb-3">
            
            <div className="relative lg:w-3/12  mb-3">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-right"
                >
                 {t("height")}
                </label>
            
                <div className="lg:w-auto px-1">
                    <div className=" text-right  text-xs">
                      <SimpleDropDown maxValue="2.00" selectedValue={height} minValue="1.55" className="text-xs2 text-right" id="height" name="height" onChange={setHeight} handleErrors={handleErrors}/>
                    </div>
                    <span className="block text-right text-xs mb-2 py-2 text-red-500" >{validationErrors["height"]}</span>
                  </div>
                  </div>
                  {gender && gender == "Female" ? (      <div className="lg:w-9/12 px-1 mb-8">
                <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-right"
                  >
                   {t("height_agreement_female")}
                  </label>
                  <div class="flex flex-row-reverse h-5">
                    <input id="shorter" name="shorter" checked={heightAgreementLess != undefined && heightAgreementLess == true} type="checkbox"   onChange={(e) => {
                        if (e.target.checked) {
                          setHeightAgreementLess(true);
                        } else {
                          console.log("here changing to false, e.target.checked: ", e.target.checked);
                          setHeightAgreementLess(false);
                        }
                      }
                      } class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"/>
                    <label for="shorter" class="text-gray-700 text-xs text-right px-1">{t("shorter")}</label>
                  </div>
                  <div class="flex flex-row-reverse h-5">
                    <input id="identical" type="checkbox"  checked={heightAgreementSame != undefined && heightAgreementSame == true} onChange={(e) => {
                       if (e.target.checked) {
                        setHeightAgreementSame(true);
                      } else {
                        setHeightAgreementSame(false);
                      }
                    }} class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"/>
                    <label for="identical" class="text-gray-700 text-xs text-right px-1">{t("identical")}</label>
                  </div>
                  </div>
              ) : (
                <div className="lg:w-9/12 px-1 mb-8">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-right"
                >
                 {t("height_agreement")}
                </label>
                <div class="flex flex-row-reverse h-5">
                  <input id="higher" type="checkbox" checked={heightAgreementLess != undefined && heightAgreementLess == true}  onChange={(e) => {
                        if (e.target.checked) {
                          setHeightAgreementLess(true);
                        } else {
                          console.log("here changing to false, e.target.checked: ", e.target.checked);
                          setHeightAgreementLess(false);
                        }
                      }
                      } class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"/>
                  <label for="higher" class="text-gray-700 text-xs text-right px-1">{t("higher")}</label>
                </div>
                <div class="flex flex-row-reverse h-5">
                  <input id="identical" type="checkbox"  checked={heightAgreementSame != undefined && heightAgreementSame == true} onChange={(e) => {
                       if (e.target.checked) {
                        setHeightAgreementSame(true);
                      } else {
                        setHeightAgreementSame(false);
                      }
                    }}  class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"/>
                  <label for="identical" class="text-gray-700 text-xs text-right px-1">{t("identical")}</label>
                </div>

                </div>
              )}
                
                  </div>
             {/* weight */}
             <div className=" w-full flex flex-row-reverse px-4">
                <div className="relative  mb-8">
                  <RadioButtonList name="fat" checkedValue={fat} onChange={setFat} title={gender=="Female" ? t("wighet_title_female") : t("wighet_title")} values={fatOptions} id="fat" handleErrors={handleErrors} validationErrors={validationErrors}/>

            </div>
            </div>
                  {/* skin color */}

      {fatherRace && motherRace && (fatherRace != "ashkenaz" || motherRace != "ashkenaz" )? (
        
        <div className=" w-full flex flex-row-reverse px-4">
          <div className="w-full lg:w-3/12 px-4">
          <div className="relative mb-8">
          <RadioButtonList name="skinColor" checkedValue={skinColor} values={skinColors} title={t('skin_color')} onChange={setSkinColor} handleErrors={handleErrors} validationErrors={validationErrors}/>
             
          </div>
          </div>
          <div className="w-full lg:w-3/12 px-4">
          <div className="relative mb-8">
          <RadioButtonList name="darkSkinBother" id="darkSkinBother" checkedValue={darkSkinBother} values={yesNoOptions} title={t('dark_color_bother')} onChange={setDarkSkinBother} handleErrors={handleErrors} validationErrors={validationErrors}ß />
             
          </div>
          </div>
          
         </div>   
  
      ):  null} 

            <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-right"
                  >
                      {t("mother_name")}
                  </label>
                  <input
                    type="text"
                    id="motherName"
                    name="motherName"
                    defaultValue={motherName}
                    className="border-0 px-3 py-3 text-right placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    onChange={(e) => {setMotherName(e.target.value); handleErrors(e.target.id)}}
                  />
                    <span className="block text-right text-xs mb-2 py-2 text-red-500" >{validationErrors["motherName"]}</span>
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-right"
                  >
                    {t("father_name")}
                  </label>
                  <input
                    type="text"
                    id="fatherName"
                    name="fatherName"
                    defaultValue={fatherName}
                    className="border-0 px-3 py-3 text-right placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    onChange={(e) => {setFatherName(e.target.value); handleErrors(e.target.id)}}
                  />
                     <span className="block text-right text-xs mb-2 py-2 text-red-500" >{validationErrors["fatherName"]}</span>
                </div>
              </div>

             {gender ? ( <div className=" w-full flex flex-row-reverse px-4">
                <div className="relative mb-5">
                {gender && gender == "Male" ? (<RadioButtonList name="smoke" id="smoke" checkedValue={smoker} onChange={setSmoker} title={gender =="Female" ? t("agree_to_smoke"): t("smoke")} values={smokeOptions} handleErrors={handleErrors} validationErrors={validationErrors}/>
                ): 
               ( <RadioButtonList name="smoke" id="smoke" checkedValue={smoker} onChange={setSmoker} title={gender =="Female" ? t("agree_to_smoke"): t("smoke")} values={yesNoOptioms} handleErrors={handleErrors} validationErrors={validationErrors}/>
               )} 
              </div>
              </div>
             ) : null}
             

             <div className="w-full flex flex-row-reverse px-4">
              <div className="relative mb-5">
                  <RadioButtonList name="fromAbroad" checkedValue={fromAbroad} values={abroadLocalOptions} id="fromAbroad" title={gender === "Female" ? t("mark_the_correct_option_female") : t("mark_the_correct_option")} onChange={setFromAbroad} handleErrors={handleErrors} validationErrors={validationErrors}/>

              </div>
              </div>
   
                {fromAbroad && (fromAbroad == "from_abroad" || fromAbroad == "parents_from_abroad") ? (
        
              <div className=" w-full flex flex-row-reverse px-4">
                <div className="relative px-6">
                <RadioButtonList name="nation" checkedValue={nation} values={nations} id="nation" title={gender === "Female" ? t('nations_title_female') : t('nations_title')} onChange={setNation} handleErrors={handleErrors} validationErrors={validationErrors}/>
                   
                  </div>
                   <div className="lg:w-9/12 px-1 mb-8">
                    <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-right"
                    >
                    {gender === "Female" ? t('nations_agreement_female') : t('nations_agreement')}
                    </label>
                    <CheckBoxList values={nationsAgreement} id="nationsAgreement" onChange={setNationAgreement} value={nationAgreement} handleErrors={handleErrors} validationErrors={validationErrors}/>
         
                
               </div>   
               </div>
        
            ):  null}   
           
          
           {/* seminars/yeshivot */}
          { gender ? ( 
            
              <div className="w-full flex flex-row-reverse px-4">
                <div className="relative lg:w-3/12 mb-5 justify-end">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-right"
                  >
                   {gender=="Female" ? t("seminar_list"): t("yeshive_list")}
                  </label>
                  
                

                      <div className="relative mb-1 text-right text-xs">
                        <SimpleDropDown values={gender == "Female" ? seminarsArray: yeshivotArray} selectedValue={school} useTranslator={true} className="text-xs text-right justify-start float-start" onChange={setSchool} id="school" name="school"  handleErrors={handleErrors}/>
                      </div>
                      <span className="block text-right text-xs mb-2 py-2 text-red-500" >{validationErrors && validationErrors["school"]}</span>
                    </div>
                </div>
            
                ): null }

              <div className=" w-full flex flex-row-reverse px-4">
                <div className="relative mb-5">
                <RadioButtonList name="chasid" checkedValue={chasid} title={gender && gender == "Female" ? t("agree_to_easy_chasid"): t("easy_chasid")} values={yesNoOptions} onChange={setChasid} id="chasid" handleErrors={handleErrors} validationErrors={validationErrors}/>
              </div>
              </div>

            {gender && gender == "Male" ? (  <div className=" w-full flex flex-row-reverse px-4">
                <div className="relative mb-5">
                <RadioButtonList name="marketTime" title={t('market_time')} checkedValue={marketTime} values={marketTimeOptions} onChange={setMarketTime} id="marketTime" handleErrors={handleErrors} validationErrors={validationErrors}/>
              </div>
              </div> ) : null}

              {/* profession */}
            {gender ?  (
              gender == "Female" ? ( 
             <div className=" w-full flex flex-row-reverse px-4">
                <div className="w-full lg:w-4/12 px-3">
                <div className="relative w-full mb-3">
                  <RadioButtonList title={t('profession_female')} checkedValue={profession} name="profession" id="profession" values={professionsList} onChange={setProfession} handleErrors={handleErrors} validationErrors={validationErrors}/>
                </div>
                </div>
                <div className="w-full lg:w-4/12 px-3">
                <div className="relative w-full mb-3">
                  <RadioButtonList title={t('profession_status')} checkedValue={professionStatus} name="professionStatus" id="professionStatus" values={professionStatusOptions} onChange={setProfessionStatus} handleErrors={handleErrors} validationErrors={validationErrors}/>
                </div>
                </div>
                </div>
              ) : (
                <div className=" w-full flex flex-row-reverse px-4">
                <div className="relative mb-5">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-right"
                >
                 {t("professions_match")}
                 </label>
                 <CheckBoxList values={professionsList} id="professionAgreement" name="professionAgreement" onChange={setProfessionsAgreement} value={professionsAgreement} handleErrors={handleErrors} validationErrors={validationErrors}/>
                 </div>
                 </div>
              )
              ): null} 
            </div>
        {/* out to market time */}

      {/* religious level */}
    
        <hr className="mt-6 border-b-1 border-blueGray-300" />
          <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase font-queryFont2 justify-end text-right">
            {t("religious_level_title")}
          </h6>
          <div className="font-queryFont3">
            {/* opening */}
            <div className=" w-full flex flex-row-reverse px-4">
              <div className="relative mb-5">
                <RadioButtonList name="openLevel" checkedValue={openLevel} id="openLevel" title={gender && gender == "Female" ? t("opening_query_female"): t("opening_query")} onChange={setOpenLevel} values={openLevels} handleErrors={handleErrors} validationErrors={validationErrors}/>
              </div>
            </div>
           {/* halacha */}
           <div className=" w-full flex flex-row-reverse px-4">
                <div className="relative mb-5">
              <RadioButtonList name="halacha" checkedValue={halacha} id="halacha" values={halachaOptions} onChange={setHalacha} title={gender && gender == "Female" ? t("halacha_query_female"): t("halacha_query")} validationErrors={validationErrors} handleErrors={handleErrors}/>
            </div>
            </div>

            {gender && gender == "Male" ? (
                   <div className="flex flex-row-reverse">
                   <div className="w-full lg:w-4/12 px-3">
                   <div className="relative w-full mb-3">
                   <label
                  className="block text-blueGray-600 text-xs font-bold mb-2 text-right"
                >
                 {t("halacha_hakpada")}
                </label>
                <CheckBoxList values={halachaHakpadaMale} id="halachahakpada" name="halachahakpada" onChange={setHalachaHakpada} value={halacaHakpada} handleErrors={handleErrors} validationErrors={validationErrors}/>
                </div>
               </div>
               <div className="w-full lg:w-4/12 px-3">
                   <div className="relative w-full mb-3">
                   <RadioButtonList name="bread" values={breadOptions} id="bread" checkedValue={bread} onChange={setBread} title={t("bread_title")} handleErrors={handleErrors} validationErrors={validationErrors}/>
                   </div>
                   </div>
               </div>
               
            ): null}

            {/* internet */}
                         {openLevel && (openLevel == "very_open" || openLevel == "open" || openLevel == "average") ? (
               <div className="flex flex-row-reverse">
                <div className="w-full lg:w-4/12 px-3">
                <div className="relative w-full mb-3">
                <RadioButtonList name="internet" checkedValue={internet} title={t("internet")} id="internet" values={intenetOptions} onChange={setInternet} handleErrors={handleErrors} validationErrors={validationErrors}/>
            </div>
            </div>

          {/* smartphone */}
            <div className="w-full lg:w-4/12 px-3">
            <div className="relative w-full mb-3">
            <RadioButtonList name="smartphone" checkedValue={smartphone} title={t("smartphone")} id="smartphone" values={smartphoneOptions} onChange={setSmartphone} handleErrors={handleErrors} validationErrors={validationErrors}/>
              </div>
              </div>


            {/* sratim */}
                   <div className="w-full lg:w-4/12 px-3">
                   <div className="relative w-full mb-3">
                   <RadioButtonList name="movies" title={t("sratim")} checkedValue={movies} id="movies" values={moviesOptions} onChange={setMovies} handleErrors={handleErrors} validationErrors={validationErrors}/>
                      </div>
                      </div>

            </div>
             ) : null }

             {/* principels */}
    
           {gender && openLevel ? ( <div className=" w-full flex flex-row-reverse px-4">
                <div className="relative mb-5">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-right" dir="rtl"
                >
                 {t("principels_list_title")} 
                 <label className="uppercase text-black text-lg font-bold mb-2 text-right" dir="rtl">  {t("principels_list_title_2")} </label>
                
                </label>
              {gender == "Female" ? (
                openLevel && (openLevel == "very_open" || openLevel == "open" || openLevel == "average") ? (
                <CheckBoxList values={openPrincipelsFemale} id="principles" name="principles" onChange={setPrincipels} value={principles} handleErrors={handleErrors} validationErrors={validationErrors}/>
                ): (
                  <CheckBoxList values={closePrincipelsFemale} id="principles" name="principles" onChange={setPrincipels} value={principles} handleErrors={handleErrors} validationErrors={validationErrors}/>
                )
              ): (
                openLevel && (openLevel == "very_open" || openLevel == "open" || openLevel == "average") ? (
                  <CheckBoxList values={openPrincipels} id="principles" name="principles" onChange={setPrincipels} value={principles} handleErrors={handleErrors} validationErrors={validationErrors}/>
                  ): (
                    <CheckBoxList values={closePrincipels} id="principles" name="principles" onChange={setPrincipels} value={principles} handleErrors={handleErrors} validationErrors={validationErrors}/>
                  )
              )}
            </div>
            </div>) : null}

             {/* modesty */}
            {gender ? (
             <div className="flex flex-row-reverse">
                <div className="w-full lg:w-4/12 px-3">
                <div className="relative w-full mb-3">
                  <RadioButtonList name="modestyLevel" checkedValue={modestyLevel} id="modestyLevel" title={gender == "Female" ? t("modesty_level_female"): t("modesty_level_male")} values={modestyLevels} onChange={setModestyLevel} handleErrors={handleErrors} validationErrors={validationErrors}/>
                  </div>
                </div>

           {gender == "Female" ? ( <div className="w-full lg:w-4/12 px-3">
                <div className="relative w-full mb-3">
                  <RadioButtonList name="modesty-socks" checkedValue={socks} title={t("modesty_socks")} values={socksType} onChange={setSocks} handleErrors={handleErrors} validationErrors={validationErrors}/>
                  </div>
                </div>  
           ) : (
            openLevel && openLevel != "very_open" ?   ( <div className="w-full lg:w-4/12 px-3">
            <div className="relative w-full mb-3">
              <RadioButtonList name="eyesSaving" id="eyesSaving" checkedValue={eyesSaving}  title={t("eyes_saving_level")} values={eyesSavingLevel} onChange={setEyesSaving} handleErrors={handleErrors} validationErrors={validationErrors}/>
              </div>
            </div> ) : null )
            }
            </div>
            ): null}

                 {/* rabamin + kashrut */}
                 <div className="flex flex-row-reverse">
                <div className="w-full lg:w-4/12 px-3">
                <div className="relative w-full mb-3">
                  <RadioButtonList name="rabays" checkedValue={rabays} id="rabays" title={gender && gender == "Female" ? t("mark_the_correct_option_female"): t("mark_the_correct_option")} values={rabaysLevels} onChange={setRabays} handleErrors={handleErrors} validationErrors={validationErrors}/>
                  </div>
                </div>

                <div className="w-full lg:w-4/12 px-3">
                <div className="relative w-full mb-3">
                  <RadioButtonList name="kashrut" id="kashrut" checkedValue={kashrut} title={t("kashrut_title")} values={kashrutLevel} onChange={setKashrut} handleErrors={handleErrors} validationErrors={validationErrors}/>
                  </div>
                </div> 
                </div>

              {/* positive pronciples */}
              {gender && gender == "Male" ?  (
              <div className=" w-full flex flex-row-reverse px-4">
                <div className="relative mb-5">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-right"
                >
                 {t("positive_principels_list_title")}
                </label>
                  <CheckBoxList values={positivePrinciplesList} id="positivePrinciples" name="positivePrinciples" onChange={setPositivePrincipels} value={positivePrinciples} handleErrors={handleErrors} validationErrors={validationErrors}/>
                </div>
              </div>
              ): null}
              
              {/* tora */}
           {gender ? (   
           <div className="flex flex-row-reverse">
               {gender == "Female" ? ( 
             
                <div className="w-full lg:w-4/12 px-3">
                <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-right"
                >
                 {t("tora_plans_female")}
                </label>
                <CheckBoxList values={toraPlansOptions} id="toraPlansAgreement" name="toraPlansAgreement" onChange={setToraPlansAgreement} value={toraPlansAgreement} handleErrors={handleErrors} validationErrors={validationErrors}/>
                </div>
               
                </div>
               ): (
                <div className="w-full lg:w-4/12 px-3">
                <div className="relative w-full mb-3">
                  <RadioButtonList name="toraPlans" id="toraPlans" checkedValue={toraPlans} title={t("tora_plans")} values={toraPlansOptions} onChange={setToraPlans} handleErrors={handleErrors} validationErrors={validationErrors}/>
                  </div>
                </div>
               )}
                <div className="w-full lg:w-4/12 px-3">
                <div className="relative w-full mb-3">
                  <RadioButtonList name="toraSacrifice" id="toraSacrifice" checkedValue={toraSacrifice} title={gender == "Female" ? t("tora_sacrifice_female"): t("tora_sacrifice")} values={toraSacrificeOptions} onChange={setToraSacrifice} handleErrors={handleErrors} validationErrors={validationErrors}/>
                  </div>
                </div>
               
                {gender == "Male" ? (
                    <div className="w-full lg:w-4/12 px-3">
                    <div className="relative w-full mb-3">
                      <RadioButtonList name="toraHatmada" id="toraHatmada" checkedValue={toraHatmada} title={t("hatmada_definition")} values={hatmadaOptions} onChange={setToraHatmada} handleErrors={handleErrors} validationErrors={validationErrors}/>
                      </div>
                    </div>  
                ): null}
                </div>
           ) : null}
          
          <hr className="mt-6 border-b-1 border-blueGray-300" />
</div>
<h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase justify-end text-right font-queryFont2">
  {t("personality")}
</h6>
<div className="font-queryFont3">
  <div className=" w-full flex flex-row-reverse px-4">
          <div className="relative mb-5">
          <label
            className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-right"
          >
           { gender && gender == "Female" ? t("interests_female") : t("interests")}
          </label>
        
          <CheckBoxList values={interests} id="interests" name="interests" onChange={setInterestsList} value={interestsList} handleErrors={handleErrors} validationErrors={validationErrors}/>
          
      </div>
      </div>
      <div className=" w-full flex flex-row-reverse px-4">
          <div className="relative mb-5">
          <label
            className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-right"
          >
           {gender && gender == "Female" ? t("hobbies_female") : t("hobbies")}
          </label>
        
          <CheckBoxList values={hobbies} id="hobbies" name="hobbies" onChange={setHobbiesList} value={hobbiesList} handleErrors={handleErrors} validationErrors={validationErrors}/>
          
      </div>
      </div>

               {/* smartness + intelleegnce */}
               <div className="flex flex-row-reverse">
          <div className="w-full lg:w-4/12 px-3">
          <div className="relative w-full mb-3">
            <RadioButtonList name="smartness" id="smartness" checkedValue={smartness} title={gender && gender == "Female" ? t("smartness_question_female") : t("smartness_question")} values={smartnessOptions} onChange={setSmartness} handleErrors={handleErrors} validationErrors={validationErrors}/>
            </div>
          </div>

          <div className="w-full lg:w-4/12 px-3">
          <div className="relative w-full mb-3">
            <RadioButtonList name="intellegnce" id="intellegnce" checkedValue={intellegnce} title={t("intellegence")} values={intellegnceOptions} onChange={setIntellegnce} handleErrors={handleErrors} validationErrors={validationErrors}/>
            </div>
          </div> 
          </div>

 

                 {/* society + appearnce */}
          <div className="flex flex-row-reverse">
          <div className="w-full lg:w-4/12 px-3">
          <div className="relative w-full mb-3">
            <RadioButtonList name="appearnce" id="appearnce" checkedValue={appearnce} title={t("apperance_question")} values={appearnceOptions} onChange={setAppearance} handleErrors={handleErrors} validationErrors={validationErrors}/>
            </div>
          </div>

          <div className="w-full lg:w-4/12 px-3">
          <div className="relative w-full mb-3">
            <RadioButtonList name="societyInfluence" id="societyInfluence" checkedValue={societyInfluece} title={t("society_influence")} values={societyOptions} onChange={setSocietyInfluence} handleErrors={handleErrors} validationErrors={validationErrors}/>
            </div>
          </div> 
          </div>   

      {/* personlity questions */}
          <div className=" w-full flex flex-row-reverse px-4">
            <div className="relative mb-5">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-right"
              >
              {t("personality_questions")}
              </label>
        
              <PersonalityTestCheckBox checkedValues={personalityTestList} values={personalityTestQuestions} id="personalityTestList" name="personalityTestList" onChange={setPersonalityTestList} value={personalityTestList} handleErrors={handleErrors} validationErrors={validationErrors}/>
          
          </div>
        </div>         
            </div>
            
                 {/* life style  */}
          <hr className="mt-6 border-b-1 border-blueGray-300" />
          <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase justify-end text-right font-queryFont2">
            {t("life_title")}
          </h6>
               {/* wealth level */}
               <div className="font-queryFont3">
               <div className="flex flex-row-reverse">
               <div className="w-full lg:w-4/12 px-3">
               <div className="relative w-full mb-3">
                 <RadioButtonList name="lifestyleLevelToday" checkedValue={lifestyleLevelToday} id="lifestyleLevelToday" title={gender && gender == "Female" ? t("lifestyle_today_female") : t("lifestyle_today")} values={wealthLevelOptions} onChange={setLifestyleLevelToday} handleErrors={handleErrors} validationErrors={validationErrors}/>
                 </div>
               </div>

               <div className="w-full lg:w-4/12 px-3">
               <div className="relative w-full mb-3">
                 <RadioButtonList name="lifestyleLevelFuture" checkedValue={lifestyleLevelFuture} id="lifestyleLevelFuture" title={gender && gender == "Female" ? t("lifestyle_future_female") : t("lifestyle_future")} values={wealthLevelOptions} onChange={setLifestyleLevelFuture} handleErrors={handleErrors} validationErrors={validationErrors}/>
                 </div>
               </div> 
               </div>


                {/* Family Living loation */}
                <div className="font-queryFont3">
               <div className="flex flex-row-reverse">
               <div className="w-full lg:w-4/12 px-3">
               <div className="relative w-full mb-3">
                 <RadioButtonList name="livingLocationToday" checkedValue={livingLocationToday} id="livingLocationToday" title={t("living_location_today")} values={livingLocationTodayList} onChange={setLivingLocationToday} handleErrors={handleErrors} validationErrors={validationErrors}/>
                 </div>
               </div>

               <div className="w-full lg:w-4/12 px-3">
               <div className="relative w-full mb-3">
                 <RadioButtonList name="offersAreaPrefer" checkedValue={offersAreaPrefer} id="offersAreaPrefer" title={t("offers_area_prefer")} values={offersAreaPreferList} onChange={setOffersAreaPrefer} handleErrors={handleErrors} validationErrors={validationErrors}/>
                 </div>
               </div> 
               </div>
               </div>

                {/* living  place */}
               <div className="w-full flex flex-row-reverse px-4">
               <div className="relative mb-5">
               <RadioButtonList name="livingLocationPrefer" checkedValue={livingLocationPrefer} id="livingLocationPrefer" title={t("living_prefer")} values={livingPrefer} onChange={setLivingLocationPrefer} handleErrors={handleErrors} validationErrors={validationErrors}/>
                 </div>
               </div>
             <div className=" w-full flex flex-row-reverse px-4">
               <div className="w-full lg:w-6/12 px-4">
               <div className="relative w-full mb-3">
               <label
                 className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-right"
               >
                {gender && gender == "Female" ? t("living_negative_female") : t("living_negative")}
               </label>
             
               <CheckBoxList values={livignNegative} id="livingNegs" name="livingNegs" onChange={setLivingNegs} value={livingNegs} handleErrors={handleErrors} validationErrors={validationErrors}/>
               
               </div>
               </div>

               <div className="w-full lg:w-6/12 px-4">
               <div className="relative w-full mb-3">
                 <RadioButtonList name="livingObligation" checkedValue={locationObligation}  id="livingObligation" title={gender && gender == "Female" ? t("living_obligation_female") : t("living_obligation")} values={livingObligation} onChange={setLocationObligation} handleErrors={handleErrors} validationErrors={validationErrors}/>
                 </div>
               </div>
               </div>

               
               <div className=" w-full flex flex-row-reverse px-4">
                <div className="relative mb-5">
                 <RadioButtonList name="politicRelation" checkedValue={politicRelation}  id="politicRelation" title={t("politic_question")} values={politicRelationList} onChange={setPoliticRelation} handleErrors={handleErrors} validationErrors={validationErrors}/>
                 </div>
               </div> 
       


               {fromAbroad && fromAbroad == "from-abroad" ? (
                 <div className="w-full flex flex-row-reverse px-4">
                 <div className="relative mb-5">
                 <RadioButtonList name="livingAbroad" checkedValue={abroadPlans} title={t("abraod_living_plans")} values={yesNoOptions} onChange={setAbroadPlans}/>
                   </div>
                 </div>
               ): null}
      
      {/* family*/}
             <div className="flex flex-row-reverse">
               <div className="w-full lg:w-4/12 px-3">
               <div className="relative w-full mb-3">
                 <RadioButtonList name="familyDefinition" checkedValue={familyDefinition} id="familyDefinition" title={gender && gender == "Female" ? t("family_declartion_female") : t("family_declartion")} values={familyDefintion} onChange={setFamilyDefinition} handleErrors={handleErrors} validationErrors={validationErrors}/>
                 </div>
               </div>

               <div className="w-full lg:w-4/12 px-3">
               <div className="relative w-full mb-3">
                 <RadioButtonList name="familyStyle" id="familyStyle" checkedValue={familyImportance} title={t("family_importance")} onChange={setFamilyImportance} values={familyStyle} handleErrors={handleErrors} validationErrors={validationErrors}/>
                 </div>
               </div> 
             </div>
     
         {familyImportance && familyImportance == "yichus" || familyImportance == "important" ? (
            <div className="w-full flex flex-row-reverse px-4">
            <div className="w-full lg:w-4/12 px-3">
               <div className="relative w-full mb-3">
            <RadioButtonList name="simpleFamilyBother" checkedValue={simpleFamilyBother} id="simpleFamilyBother" title={t("simple_bother")} values={yesNoOptions} onChange={setSimpleFamilyBother} handleErrors={handleErrors} validationErrors={validationErrors}/>
              </div>
            </div>
          
           {familyImportance == "yichus" ? (
             <div className="w-full lg:w-4/12 px-3">
               <div className="relative w-full mb-3">
            <RadioButtonList name="yichusImportant" checkedValue={yichusMatters} title={t("yichus_important")} values={yesNoOptions} onChange={setYichusMatters} handleErrors={handleErrors} validationErrors={validationErrors}/>
              </div>
            </div>
          
            ): null }
              </div>
         ): null}

         {/* family size */}
         <div className="flex flex-row-reverse">
               <div className="w-full lg:w-4/12 px-3">
               <div className="relative w-full mb-3">
                 <RadioButtonList name="familySize" id="familySize" checkedValue={familySize} title={t("family_size")} values={familySizeOptions} onChange={setFamilySize} handleErrors={handleErrors} validationErrors={validationErrors}/>
                 </div>
               </div>

               <div className="w-full lg:w-4/12 px-3">
               <div className="relative w-full mb-3">
                 <RadioButtonList id="smallFamilyBother" name="smallFamilyBother" checkedValue={smallFamilyBother} title={t("small_family_bother")} onChange={setSmallFamilyBother} values={yesNoOptions} handleErrors={handleErrors} validationErrors={validationErrors}/>
                 </div>
               </div> 
             </div>

             {/* family quetions */}
            <div className="flex flex-row-reverse">
               <div className="w-full lg:w-4/12 px-3">
               <div className="relative w-full mb-3">
                 <RadioButtonList id="hasNoshrim" name="hasNoshrim" checkedValue={hasNoshrim} title={t("has_noshrim")} values={yesNoOptions} onChange={setHasNoshrim} handleErrors={handleErrors} validationErrors={validationErrors}/>
                 </div>
               </div>

               <div className="w-full lg:w-4/12 px-3">
               <div className="relative w-full mb-3">
                 <RadioButtonList id="workersBrothers" name="workersBrothers" checkedValue={workersBrothers} title={t("workers_brohters")} onChange={setWorkersBrothers} values={yesNoOptions} handleErrors={handleErrors} validationErrors={validationErrors}/>
                 </div>
               </div> 
             </div>

             <div className="flex flex-row-reverse">
               <div className="w-full lg:w-4/12 px-3">
               <div className="relative w-full mb-3">
                 <RadioButtonList id="cancelShiduch" name="cancelShiduch" checkedValue={cancelShiduch} title={t("cancel_shiduch")} values={yesNoOptions} onChange={setCancelShiduch} handleErrors={handleErrors} validationErrors={validationErrors}/>
                 </div>
               </div>

               <div className="w-full lg:w-4/12 px-3">
               <div className="relative w-full mb-3">
                 <RadioButtonList id="devorsedParents" name="devorsedParents" checkedValue={devorsedParents} title={t("devorsed_parents")} onChange={setDevorsedParents} values={yesNoOptions} handleErrors={handleErrors} validationErrors={validationErrors}/>
                 </div>
               </div> 
             </div>

             <div className="flex flex-row-reverse">
               <div className="w-full lg:w-4/12 px-3">
               <div className="relative w-full mb-3">
                 <RadioButtonList name="fatherWork" id="fatherWork" checkedValue={fatherWork} handleErrors={handleErrors} validationErrors={validationErrors} title={t("father_work")} values={fatherWorkOptions} onChange={setFatherWork}/>
                 </div>
               </div>

               <div className="w-full lg:w-4/12 px-3">
               <div className="relative w-full mb-3">
                 <RadioButtonList name="motherWork" id="motherWork" checkedValue={motherWork} title={t("mother_work")} onChange={setMotherWork} values={motherWorkOptions} handleErrors={handleErrors} validationErrors={validationErrors}/>
                 </div>
               </div> 
             </div>

{/* sfaradi mentaliyut */}
      {fatherRace && motherRace && (fatherRace != "ashkenaz" || motherRace != "ashkenaz" )? (
      
      <div className="flex flex-row-reverse">
      <div className="w-full lg:w-4/12 px-3">
      <div className="relative w-full mb-3">
          <RadioButtonList name="headCover" checkedValue={headCover} values={headCoverOptions}  title={gender === "Female" ? t('head_cover_question'): t('head_cover_question_male')} onChange={setHeadCover} handleErrors={handleErrors} validationErrors={validationErrors}/>
             
          </div>
          </div>
          <div className="w-full lg:w-4/12 px-3">
          <div className="relative w-full mb-3">
          <RadioButtonList name="siblingsSchools" checkedValue={siblingsSchools} values={siblingsSchoolsOptions} title={t('siblings_schools_question')} onChange={setSiblingsSchools} handleErrors={handleErrors} validationErrors={validationErrors}ß />
             
          </div>
          </div>
      </div>
          ):  null} 
   {gender == 'Male'  && fatherRace && motherRace && (fatherRace != "ashkenaz" || motherRace != "ashkenaz" )? (
        
          <div className="flex flex-row-reverse">
          <div className="w-full lg:w-4/12 px-3">
          <div className="relative w-full mb-3">
            <RadioButtonList id="schoolType" name="schoolType" checkedValue={schoolType} title={t("school_type_question")} values={siblingsSchoolsOptions} onChange={setSchoolType} handleErrors={handleErrors} validationErrors={validationErrors}/>
            </div>
          </div>

          <div className="w-full lg:w-4/12 px-3">
          <div className="relative w-full mb-3">
            <RadioButtonList id="talitPray" name="talitPray" checkedValue={talitPray} title={t("talit_pray")} onChange={setTalitPray} values={yesNoOptions} handleErrors={handleErrors} validationErrors={validationErrors}/>
            </div>
          </div> 
          </div>
          
):  null} 

{fatherRace && motherRace && (fatherRace != "ashkenaz" || motherRace != "ashkenaz" )? (
      
      <div className="flex flex-row-reverse">
      <div className="w-full lg:w-4/12 px-3">
      <div className="relative w-full mb-3">
          <RadioButtonList name="shul" checkedValue={shul} values={shulOptions}  title={t('shul_question')} onChange={setShul} handleErrors={handleErrors} validationErrors={validationErrors}/>
             
          </div>
          </div>
          </div>
          ):  null} 

       {/* money*/}

       {gender && gender == "Male" ? (<div className="w-full flex flex-row-reverse px-4">
            <div className="w-full lg:w-4/12 px-3">
               <div className="relative w-full mb-3">
            <RadioButtonList name="moneyDemands" id="moneyDemands" checkedValue={moneyDemands} title={t("money_demands")} onChange={setMoneyDemands} values={moneyDemandsOptions} handleErrors={handleErrors} validationErrors={validationErrors}/>
              </div>
            </div>
            {moneyDemands && (moneyDemands == "flexable_low" || moneyDemands == "flexable_high") ? (
              <div className="w-full lg:w-4/12 px-3">
              <div className="relative w-full mb-3">
              <label
                   className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-right"
                 >
                   {gender && gender == "Female" ? t("please_enter_the_amount_female") : t("please_enter_the_amount")}
                 </label>
                 <input
                   type="text"
                   defaultValue={moneyDemandsNumber > 0 ? moneyDemandsNumber : undefined}
                   id="moneyDemandsNumber"
                   className="border-0 px-3 py-3 text-right placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                   onChange={(e) => {{setMoneyDemandsNumber(e.target.value); handleErrors(e.target.id)}}}
                 />
                   <span className="block text-right text-xs mb-2 py-2 text-red-500" >{validationErrors && validationErrors["moneyDemandsNumber"]}</span>
             </div>
           </div>

            ) : null}
       </div>): null}

       <div className="w-full flex flex-row-reverse px-4">
            <div className="w-full lg:w-4/12 px-3">
               <div className="relative w-full mb-3">
            <RadioButtonList name="moneyGives" checkedValue={moneyGives} id="moneyGives" title={gender && gender == "Female" ? t("interested_to_give_female") : t("interested_to_give")} onChange={setMoneyGives} values={moneyGivesOptions} handleErrors={handleErrors} validationErrors={validationErrors}/>
              </div>
            </div>
            {moneyGives && moneyGives == "specific_amount" ? (
              <div className="w-full lg:w-4/12 px-3">
              <div className="relative w-full mb-3">
              <label
                   className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-right"
                 >
                   {gender && gender == "Female" ? t("please_enter_the_approx_female") : t("please_enter_the_approx")}
                 </label>
                 <input
                   type="text"
                   defaultValue={moneyGivesNumber > 0 ? moneyGivesNumber : undefined}
                   className="border-0 px-3 py-3 text-right placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                   onChange={(e) => {setMoneyGivesNumber(e.target.value)}}
                 />
             </div>
           </div>

            ) : null}
       </div>
       </div>




      {/* about me */}
      <hr className="mt-6 border-b-1 border-blueGray-300" />

      <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase justify-end text-right font-queryFont2">
        {t("about_me_title")}
      </h6>


      <div className="w-full flex flex-row-reverse px-2">
      <div className="w-full lg:w-4/12 px-2">
        <div className="relative w-full mb-3">
        
         
        <label class="block uppercase text-blueGray-600 text-xs text-right mb-1 btn btn-primary font-queryFont3 font-bold">
          <i class="fa fa-file"></i>  {t('upload_cv_file')}<input type="file" style={{ display: "none"}} onChange={fileUpload} name="image"/>
        
        { isFilePicked && selectedFile && selectedFile.name? (
         <span className="block text-right text-xs mb-2 py-2 text-blueGray-400"> {selectedFile.name} </span>
         
        ): null}
        </label>
        <span className="block text-right text-xs mb-5 py-2 font-queryFont3 text-red-500" >{validationErrors && validationErrors["cvFile"]}</span>

        {/* <input type="file" className="btn-file-upload" value='upload_cv_file'/>  */}
        {/* <div class="custom-file">
  <input type="file" class="custom-file-input"/>
  <label class="custom-file-label">{t('upload_cv_file')}</label>
</div> */}
{/* <div>
         <input id="upload" type="file" name="upload"/>

<label class="custom-file-label" for="upload">Drop it like it's hot</label>
</div> */}

        {/* <input type="file" name="file"  id="file"onChange={fileUpload} className="inputfile text-xs text-right"/>
        <label
          className="block uppercase text-blueGray-600 text-xs text-right font-bold mb-5"
          for="file"
        >
           {t('upload_cv_file')}
            </label> */}
        </div>
      </div>
      </div>


      <div className="w-full flex flex-row-reverse px-4 font-queryFont3">
      <div className="relative w-full mb-3">
    
        <label
          className="block uppercase text-blueGray-600 text-xs text-right font-bold mb-5"
        >
        {gender && gender == "Female" ? t("about_me_female") : t("about_me")}
        </label>
       

        <textarea
          type="text"
          className="border-0 px-3 py-3 placeholder-blueGray-300 mb-10 text-blueGray-600 text-right bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          defaultValue={aboutMe}
          onChange={(e) => setAboutMe(e.target.value)}
          rows="4"
        ></textarea>


<div>
      <label
          className="block uppercase text-blueGray-600 text-xs text-right font-bold mb-5"
        >
        {gender && gender == "Female" ? t("enter_shadchan_details_female") : t("enter_shadchan_details")}
        </label>
        <input
        type="text"
        id="shadchanCode"
        name="shadchanCode"
        defaultValue={shadchanCode}
        className="border-0 float-right px-3 py-3 text-right placeholder-blueGray-300 mb-10 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring  ease-linear transition-all duration-150"
        onChange={(e) => {setShadchanCode(e.target.value); handleErrors(e.target.id)}}
        />
       <span className="block text-right text-xs mb-2 py-2 text-red-500" >{validationErrors && validationErrors["shadchanCode"]}</span>

  </div>

        <button
          className="bg-lightBlue-500 text-white active:bg-lightBlue-600 mt-20 px-2 font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 disabled:opacity-50 ease-linear transition-all duration-150"
          type="button" onClick={ () => {setSavedButtonDisabled(true); handleSubmit()}}
          disabled={saveButtonDisabed}
        >
          {t('submit')}
        </button>
           {/* {validationErrors && afterSubmit ? (<span className="block text-right text-xs mb-2 py-2 text-red-500" >{t('validation_errors')}</span>): null} */}
    </div>
</div>

    </div>



          </form>
        </div>
      </div>
      </div>
    </>
  );
}
