import React from "react";
import Select from 'react-select'
import { useTranslation } from "react-i18next";


export default function SimpleDropDown(props) {
    // check about parameters, min value max value of dropdown
    const { t, i18n } = useTranslation();

    // let maxValue = 2005; // supposed to be this year - 18
    // let minValue = 1980;
    let propsValues = props.values;
    let values = [];
    let onChange = props.onChange;
    let handleErrors = props.handleErrors;
    let selectedValue = props.selectedValue;
    let useTranslator = props.useTranslator ? true : false; 

    if (useTranslator && selectedValue != undefined && selectedValue != '') {
        selectedValue = t(selectedValue);
    }
    if (!propsValues) {

        if (props.minValue.indexOf('.') != -1){
            // if float value
            let minValue = parseFloat(props.minValue);
            let maxValue = parseFloat(props.maxValue);
            propsValues = [];
            for(let x = minValue; x <= maxValue; x+=0.01) {
                propsValues.push(x.toFixed(2));
            }
            values = propsValues.map((x) => {return(<option key={x}>{x}</option>)});
        } else {
            //console.log('Drop down values');
            let minValue = parseInt(props.minValue);
            let maxValue = parseInt(props.maxValue);
            propsValues = [];
            for(let x = minValue; x <= maxValue; x++) {
                // console.log(`Pushing value - ${x}`);
                propsValues.push(x);
            }
            values = propsValues.map((x) => {return(<option key={x}>{x}</option>)});
            }   
    } else {
        //console.log(propsValues);
       // react -select
        // propsValues.forEach(element => {
        //     values.push({ label:element, value: element })
        // });
        values = propsValues.map((x) => {return(<option key={x}>{t(x)}</option>)});
        // console.log(values);
       
       
    }
    values.unshift(<option disabled selected value style={{"display": "none;"}}>{t('please_select_value')}     </option>)
    
    return (
        <>
        {/* <Select  options={values} className= {props.className}>
         
        </Select> */}
        <select className= {props.className} placeholder="" value={selectedValue} id={props.id} name={props.name} onChange={(e) => 
            {
                // console.log(e.target);
                // console.log(e.target.selectedIndex);
                // console.log(e.target.selectedOptions);
                // console.log(propsValues[e.target.selectedIndex]);
                // console.log(`Settiing selected valus: ${propsValues[e.target.selectedIndex - 1]}`)
                onChange(propsValues[e.target.selectedIndex - 1]);
                console.log(`target id --- ${e.target.id}`)
                handleErrors(e.target.id)}}>
            {values}
        </select>
        </>
    );
}