import React from "react";


export default function RadioButtonList(props) {

    let checkedValue = props.checkedValue;
    let propsValues = props.values;
    let name = props.name;
    let title = props.title;
    let onChange = props.onChange;
    let handleErrors = props.handleErrors;
    let id = props.id;
    let validationErrors = props.validationErrors;
    
    var values;
    if (onChange) {
        values = propsValues.map((x) => {return(
            <div class="flex flex flex-row-reverse flex-wrap">
            <input id={name + "_" + x.id} value={x.id} name={name} type="radio" checked={checkedValue != undefined && checkedValue == x.id} onChange={(e) => {let value = e.target.value; if (value == 'true' ) {value = true;} if (value == 'false') {value=false;} onChange(value); handleErrors(props.id)}} class="focus:ring-indigo-500 h-4 w-4 text-right text-indigo-600 border-gray-300 jusify-end"/>
            <label for={name + "_" + x.id} class="ml-3 block text-xs text-gray-900 text-right px-1 mb-1">
            {x.label}
            </label>
          </div>
        )});
    } else {  
    values = propsValues.map((x) => {return(
        <div class="flex flex flex-row-reverse flex-wrap">
        <input id={name + "_" + x.id} name={name} value={x.id} checked={checkedValue != undefined && checkedValue == x.id} type="radio" class="focus:ring-indigo-500 h-4 w-4 text-right text-indigo-600 border-gray-300 jusify-end"/>
        <label for={name + "_" + x.id} class="ml-3 block text-xs text-gray-900 text-right px-1 mb-1">
        {x.label}
        </label>
      </div>
    )});
    }
    
    return (
        <>
        <div>
        <fieldset>
            <div>
                <p class="text-xs mb-1 text-blueGray-600 font-bold text-right"> {title}</p>
            </div>
            <div class="mt-4 space-y-4"></div>
                {values}
        </fieldset>  
        <span className="block text-right text-xs mb-2 py-2 text-red-500" >{validationErrors && validationErrors[id]}</span>
        </div>
        </>
    );
}