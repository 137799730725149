import React,  { useState, useEffect } from 'react'
import { Redirect, Route, useHistory } from 'react-router-dom'
import { convertToObject } from 'typescript';

const PrivateRoute = ({ comp: Component, ...rest }) => {

  const [user, setUser] = useState("");
  const [datenow, setDatenow] = useState(new Date());
  const [expired, setExpired] = useState(false);
  const history = useHistory();

  useEffect(() => {
      const loggedInUser = localStorage.getItem("user"); 
      // console.log('Inside use effect');
      if (loggedInUser) {
          const expiration = localStorage.getItem("expiration");
          if (expiration && expiration > datenow) {
            localStorage.removeItem('user');
            localStorage.removeItem('expiration');
            localStorage.removeItem('user_state');
            // console.log('User token expired');
            setExpired(true);
          } else {
            const foundUser = loggedInUser;
            setUser(foundUser); 
        }
      }
    });

  const expiration = localStorage.getItem("expiration");
  const isLoggedInUser = user != "" || (localStorage.getItem("user") && localStorage.getItem("user") != "");
  let notExpired = true;
  try {
     let expirationDate = Date.parse(expiration);
    //  console.log('expiration date: ', expirationDate);
    //  console.log('expiration: ', expirationDate, 'date now: ', datenow.getTime());
     if (expiration) {
        // console.log('Inside compare method');
        notExpired = expirationDate > datenow.getTime()
        // console.log('not expired: ', notExpired);
        if (notExpired == false) {
          localStorage.removeItem('user');
          localStorage.removeItem('expiration');
          localStorage.removeItem('user_state');
          // console.log('User token expired');
          setExpired(true);
          let path = '/login';
          history.push(path);
        }
     }
  } catch(err) {
    // console.log('Error not expired: ', err);
  }
  // console.log('not expired: ', notExpired);

  return (
    <Route
    // TODO: need to fix this (without the rest redirects the page, with it allows the query page to render)
        {...rest}
      render={props =>
        isLoggedInUser == true && notExpired == true  ?  (
         <Component {...props} />
        ) : (
        <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  )
}

export default PrivateRoute