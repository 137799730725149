import React, { useState, useEffect, setState } from "react";
import { useHistory } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
//import "./Login.css";

import Navbar from "../components/Navbars/AuthNavbar.js";
import { sendUnauthenticatedRequest } from "../components/rest.js";

export default function SignIn() {  
    const history = useHistory();
    const { t, i18n } = useTranslation();
  
    const [user, setUser] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [weakPassword, setWeakpassword] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [usedUsername, setUsedUserName] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [showPassVerification, setShowPassVerification] = useState(false);
    const [passwordVerification, setPasswordVerification] = useState("");
    const [notMatchingPasswords, setNotMatchingPasswords] = useState(false);

  useEffect(() => {
      const loggedInUser = localStorage.getItem("user");
      if (loggedInUser) {
        //console.log(loggedInUser);
        //const foundUser = JSON.parse(loggedInUser);
        const foundUser = loggedInUser;
        setUser(foundUser);          
      }
  });

  const validateEmail = (email) => {
      return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };  

  const  validateRegister = () => {
      //Regular Expressions
    if (!validateEmail(email)) {
      setInvalidEmail(true);
      return false;
    }
    if (password != passwordVerification) {
      setNotMatchingPasswords(true);
      return false;
    }

    let regex = new Array();
    regex.push("[A-Z]"); //For Uppercase Alphabet
    regex.push("[a-z]"); //For Lowercase Alphabet
    regex.push("[0-9]"); //For Numeric Digits
    regex.push("[$@$!%*#?&]"); //For Special Characters

    
    if (password.length > 8) {
      let passed = 0;
      //Validation for each Regular Expression
      for (var i = 0; i < regex.length; i++) {
          if((new RegExp (regex[i])).test(password)){
              passed++;
          }
      }
      if (passed < 3) {
        setWeakpassword(true);
        return false;
      } else {
        return true;
      }
      
    } else {
      setWeakpassword(true);
      return false;
    }
  }
  

    async function createAccount() {

      if (validateRegister()) {
        let user = {
          username: username, 
          password: password, 
          phone: phone, 
          email: email
        }
        // console.log("User: ", user);
        const url = "/user/register";
        try {
          let response = await sendUnauthenticatedRequest(url, 'POST', JSON.stringify(user));
          // console.log(response);
          // console.log('response data: ', response.data);
          let path = '/login'; 
          history.push(path);
        } catch(err) {
          console.log('Failed to add new user, error: ', err);
          if (err.response.status == 400) {
            setUsedUserName(true);
          } else {
            alert(t('interal_error'));
          }

        }
      }
  }

  if (user) {
    let userState = localStorage.getItem('user_state');
    let path = '/user'; 
    if (userState && userState == "PROFILED") {
       path = '/home'; 
    }
    history.push(path);
  }

  return (
    <>
      <Navbar transparent />
      <main className="profile-page">
        <section className="relative block h-500-px">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover bg-wed-flowers"
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-50 bg-black"
            ></span>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-blueGray-200 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </section>
       
        <section className="pb-20 bg-blueGray-200 -mt-64 font-queryFont3 ">
          <div className="container mx-auto">
          <div className="flex content-center items-center justify-center h-full">
         
            <div className="w-full lg:w-6/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words bg-blueGray-50 w-full mb-8 shadow-xl rounded-lg">
                <div className="px-8 py-5 flex-auto">
                    {/* <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-400"> */}
            {/* <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0 mb-6">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center"> */}
     
                  <div className="text-center mt-8">
                  <h6 className="text-blueGray-800 text-center font-queryFont3 font-bold text-2xl mb-10 font-bold">
                      {t("signup_title")}</h6>
                  {/* <h6 className="text-blueGray-800 text-center font-queryFont3 font-bold text-2xl mb-10 font-bold">
                  {t("login_title")}
                  </h6> */}
                  <form>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-sm text-right font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          {t("signup_username")}
                        </label>
                        <input
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear text-right transition-all duration-150"
                          placeholder={t("signup_username_trans")}
                          onChange={(e) => {setUsedUserName(false); setUsername(e.target.value)}}
                        />
                      </div>
    
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-sm text-right font-bold mb-2"
                          htmlFor="grid-password"
                        >
                               {t("signup_email")}
                        </label>
                        <input
                          type="email"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm text-right shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder={t("signup_email")}
                          onChange={(e) => {setInvalidEmail(false); setEmail(e.target.value)}}
                        />
                      </div>
    
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-sm text-right font-bold mb-2"
                          htmlFor="grid-password"
                        >
                         {t("signup_password")}
                        </label>
                        <input
                          type={showPass ? "text" :"password"}
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm text-right shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder= {t("signup_password")}
                          onChange={(e) => {setWeakpassword(false) ; setNotMatchingPasswords(false) ;setPassword(e.target.value)}}
                        />
                        { showPass? 
                          (<i className="absolute fas fa-eye-slash left-0 pl-3 items-center mt-3" onClick={() => setShowPass(false)}></i>):
                          (<i className="absolute fas fa-eye left-0 pl-3 items-center mt-3" onClick={() => setShowPass(true)}></i>)
                        }
                      </div>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-sm text-right font-bold mb-2"
                          htmlFor="grid-password"
                        >
                         {t("signup_password_verification")}
                        </label>
                        <input
                          type={showPassVerification ? "text" :"password"}
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm text-right shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder= {t("signup_password_verification_placeholder")}
                          onChange={(e) => {setNotMatchingPasswords(false); setPasswordVerification(e.target.value)}}
                        />
                        { showPassVerification? 
                          (<i className="absolute fas fa-eye-slash left-0 pl-3 items-center mt-3" onClick={() => setShowPassVerification(false)}></i>):
                          (<i className="absolute fas fa-eye left-0 pl-3 items-center mt-3" onClick={() => setShowPassVerification(true)}></i>)
                        }
                      </div>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-sm text-right font-bold mb-2"
                          htmlFor="grid-password"
                        >
                         {t("signup_phone")}
                        </label>
                        <input
                          type="email"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm text-right shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder= {t("signup_phone")}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
    
                      <div className="text-center mt-6">
                        <button
                          className="bg-blueGray-800 text-white active:bg-blueGray-600 mt-6 mb-6 text-lg font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                          type="button"
                          onClick={createAccount}
                        >
                         {t("create_account")}
                        </button>
                        {weakPassword ? (
                            <span className="block text-right text-xs mb-2 py-2 text-red-500" >{t('weak_password')}</span>
                        ): null}
                        {invalidEmail ? (
                            <span className="block text-right text-xs mb-2 py-2 text-red-500" >{t('invalid_email')}</span>
                        ): null}
                        {usedUsername ? (
                            <span className="block text-right text-xs mb-2 py-2 text-red-500" >{t('used_username')}</span>
                        ): null}
                        {notMatchingPasswords ? (
                            <span className="block text-right text-xs mb-2 py-2 text-red-500" >{t('not_matching_passwords_error')}</span>
                        ) :null}
                      </div>
                      
                    </form>

                  </div>
                  </div>
            
                  </div>
                  </div>
                  </div>
                  </div>

         
        </section>
        
      </main>
      {/* <Footer /> */}
    </>
  );
}




