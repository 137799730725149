import React from "react";
import QueryCard from "../components/Cards/QueryCard.js";

import Navbar from "../components/Navbars/UserNavbar";

export default function Query() {
    return (
    <>
      <Navbar transparent />
      <main>
      {/* <section className="relative block h-500-px">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover bg-ring"
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-50 bg-black"
            ></span>
          </div> */}
          <section className="relative block h-500-px">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover bg-wed-flowers"
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-50 bg-black"
            ></span>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-blueGray-200 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </section>
        <section className="relative py-16 bg-blueGray-200">
          <div className="container mx-auto px-4">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">


          
       
        {/* <div className="mx-auto px-4 relative">
          <div className="flex content-center items-center justify-end "> */}
          
           <QueryCard />
      </div>  
    </div>  
     </section>
        </main>
        </>
    )
};