const axios = require('axios');
const dotenv = require('dotenv');

dotenv.config();

export async function sendPostRequest(path, method, body=null) {
    let url = process.env.REACT_APP_BASE_BACKEND_URL + path;
    // console.log("url:" , url);
    try {
        let token = localStorage.getItem("user");
        let response = await axios({
            method: method, 
            url: url,
            data: body,
            headers: {
            Authorization: 'Bearer ' + token, 
            "Content-Type": 'application/json'
            }
        });
        return response;
    } catch(err) {
        // TODO: handle retried and errors
        // console.log('axios error ', err);
        throw err;
    }
}


export async function sendFormDataRequest(path, body) {
    let url = process.env.REACT_APP_BASE_BACKEND_URL + path;
    // console.log("url:" , url);
    try {
        //console.log(`User: ${JSON.parse(localStorage.getItem("user"))}`);
        let token = localStorage.getItem("user");
        let response = await axios({
            method: 'POST', 
            url: url,
            data: body,
            headers: {
            Authorization: 'Bearer ' + token, 
            "Content-Type": "multipart/form-data"
            }
        });
        // console.log('[sendFormDataRequest] response: ', response);
        return response;
    } catch(err) {
        // TODO: handle retried and errors
        console.log('axios error ', err);
        throw err;
    }
}

export async function sendUnauthenticatedRequest(path, method, body=null) {
    let url = process.env.REACT_APP_BASE_BACKEND_URL + path;
    // console.log("Url: ", url);

    try {
        //let response = await axios.post(url, body);
        //return response;
        let response = await axios({
            method: method, 
            url: url,
            data: body,
            headers: {
                "Content-Type": 'application/json'
                }
          });
          return response;
    } catch(err) {
            // TODO: handle retried and errors
            console.log('axios error ', err);
            throw err;
    }
}
