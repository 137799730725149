import React, {useState} from "react";
import QueryCard from "../components/Cards/QueryCard.js";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Navbar from "../components/Navbars/AuthNavbar2.js";
import Footer from "../components/Footers/Footer.js";

export default function Query2() {
    const { t, i18n } = useTranslation();
    const history = useHistory();

    const [validationErrors, setValidationErrors] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  
  const [gender, setGender] = useState("");

  const handleErrors = (id) => {
    // console.log("inside handle error functions");
    // console.log("id: ",  id);
    setValidationErrors(validationErrors => ({...validationErrors, [id]: ''}));
  }


    return (
        <>
    <main className="profile-page">
        <section className="relative block h-500-px">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover bg-ring"
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-50 bg-black"
            ></span>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-blueGray-200 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </section>
        <section className="relative py-16 bg-blueGray-200">
          <div className="container mx-auto px-4">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
              <div className="px-6">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">

                  
                  <div className="text-center mt-12">
               


                  <h3 className="text-4xl font-semibold font-queryFont4 leading-normal mb-2 text-blueGray-700 mb-2"    style={{ fontFamily: "'Bellefair', serif" }}>
                   
                   {t('query_title')}
                  </h3>
                  <div className="text-m leading-normal mt-0 mb-2 text-blueGray-400 font-queryFont3 font-bold uppercase">
                    {/* <i className="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"></i>{" "} */}
                   {t('query_page_title_2')}
                  </div>
                  </div>
                </div>
            </div>
        <div className="mt-10 py-10 border-t border-blueGray-200 text-center bg-blueGray-50">
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold font-queryFont2 uppercase text-right">
            {t("user_info_title")}
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs1 font-bold font-queryFont3 mb-2 text-right"
                    htmlFor="grid-password"
                  >
                      {t("first_name_query")}
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 text-right placeholder-blueGray-300 text-blueGray-600 bg-white rounded font-queryFont2 shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                   // defaultValue="lucky.jesse"
                   id="firstName"
                   onChange={(e) => {
                    setFirstName(e.target.value); 
                    handleErrors(e.target.id);}}
                  />
                  <span className="block text-right text-xs mb-2 py-2 text-red-500" >{validationErrors["firstName"]}</span>
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs1 font-bold  font-queryFont3 mb-2 text-right"
                    htmlFor="grid-password"
                  >
                    {t("last_name_query")}
                  </label>
                  <input
                  id="lastName"
                    type="text"
                    className="border-0 px-3 py-3 text-right placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  //  defaultValue="jesse@example.com"
               
                  onChange={(e) => {setLastName(e.target.value);  handleErrors(e.target.id);}}
                  />
                  <span className="block text-right text-xs mb-2 py-2 text-red-500" >{validationErrors["lastName"]}</span>
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-right"
                    htmlFor="grid-password"
                  >
                   {t("email_query")}
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="border-0 px-3 py-3 text-right placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  //  defaultValue="meravb@checkpoint.com"
                  onChange={(e) => {setEmail(e.target.value); handleErrors(e.target.id)}}
                  />
                   <span className="block text-right text-xs mb-2 py-2 text-red-500" >{validationErrors["email"]}</span>
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-right"
                    htmlFor="grid-password"
                  >
                    {t("phone_query")}
                   
                  </label>
                  <input
                    type="text"
                    id="phone"
                    className="border-0 px-3 py-3 text-right placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                   // defaultValue="Jesse"
                   onChange={(e) => {setPhone(e.target.value); handleErrors(e.target.id)}}
                  />
                   <span className="block text-right text-xs mb-2 py-2 text-red-500" >{validationErrors["phone"]}</span>
                </div>
              </div>
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  {/* <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-right"
                    htmlFor="grid-password"
                  >
                    {t("gender_query")}
                   
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 text-right placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    defaultValue="Female"
                  /> */}
                     <div className="relative flex min-w-0   mb-0 justify-end">
                      
                     <label className="uppercase text-blueGray-600 text-xs font-bold px-2">
                          {t("gender_male")}
                          </label>    
                          <input className="placeholder-blueGray-300 text-blueGray-600"
                            type="radio"
                            value="Male"
                            id="gender"
                            checked={gender === "Male"}
                            onChange={(e) => {setGender(e.target.value); handleErrors(e.target.id)}}
                          />
                        
                      </div>
                      <div className="radio relative flex mb-0 justify-end">
                      <label className="flex block uppercase text-blueGray-600 text-xs font-bold mb-2 text-right px-2 justify-end">
                        {t("gender_female")}
                        </label>
                          <input className="placeholder-blueGray-300 text-blueGray-600"
                            type="radio"
                            value="Female"
                            id="gender"
                            checked={gender === "Female"}
                            onChange={(e) => {setGender(e.target.value); handleErrors(e.target.id)}}
                          />
                       
                      </div>
                      <span className="block text-right text-xs mb-2 py-2 text-red-500" >{validationErrors["gender"]}</span>

                </div>
              </div>
            </div>
            </form>
            </div>
         
                  {/* <div className="flex flex-wrap justify-center">
                    <div className="w-full lg:w-9/12 px-4">
                      <p className="mb-4 text-lg leading-relaxed text-blueGray-700">
                       {t('user_page_text')}
                      </p> */}
                      {/* <a
                        href="#pablo"
                        className="font-normal text-lightBlue-500"
                        onClick={updateProfile}
                      >
                       {t('update_profile_button')}
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
            {/* </div>
          </div> */}
        </section>
      </main>
   
    </>
    )
};