
import i18next from 'i18next';
import en_us from './en-US.json';
import he from './he.json';
 
export const resources = {
  'en-US': {
    translation: {
      ...en_us
    },
  },
  'he': {
    translation: {
      ...he
    },
  }
};
 
async function instance() {
  if (!i18next.isInitialized) {
    await i18next.init({
      lng: 'he',
      keySeparator: false,
      fallbackLng: 'he',
      nonExplicitSupportedLngs: true,
      debug: false,
      resources
    });
  }
  return i18next;
}
 
export default instance;