import logo from './logo.svg';
import './App.css';
import Routes from "./Routes";
import Navbar from "react-bootstrap/Navbar";
import Login from "./containers/Login";
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import {resources} from './locale/i18n';
import { useState } from 'react';

// function setToken(userToken){

// };

// function getToken(){}


function App() {
  //const [isInitialized, setIsInitialized] = useState(false);

  if (!i18n.isInitialized) {
    i18n
      .use(initReactI18next) // passes i18n down to react-i18next
      .init({
        resources,
        lng: 'he',
        fallbackLng: 'he',
        nonExplicitSupportedLngs: true,
        keySeparator: false,
        interpolation: {
          escapeValue: true // react already safes from xss, just to be on the safe side we set it to true.
        }
      });
    // setIsInitialized(true);
  }
    
  // const [user, setUser] = userState("");

  // useEffect(() => {
  //     const loggedInUser = localStorage.getItem("user");
  //     if (loggedInUser) {
  //       const foundUser = JSON.parse(loggedInUser);
  //       setUser(foundUser); 
  //     }
  //   });
  //const token = getToken();
  // if (!user) {
    
  //   <Route exact path="/">
  //       <Home />
  //   </Route>
  //   return 
  // }

  return (
       <div className="App">
        {/* <Navbar collapseOnSelect bg="light" expand="md" className="mb-3">
          <Navbar.Brand className="font-weight-bold text-muted">
            Scratch
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar> */}
        <Routes />
       </div>
  );
}

export default App;
