import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import SimpleDropDown from "../Dropdowns/SimpleDropDown";
import RadioButtonList from "../RadioButtonList";
import CheckBoxList from "../CheckBoxList";

export function GetRaceArray(){
    const { t, i18n } = useTranslation();
    return [t("race_west"), t("race_east"), t("race_father_east"), t("race_mother_east"), t("race_yamen")];
}

export function RaceDropDown() {
    const { t, i18n } = useTranslation();
   
   return (
    <>
   <div className="relative lg:w-3/12  mb-3">
    <label
      className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-right"
      htmlFor="grid-password"
    >
     {t("race")}
    </label>
    

  <div className="relative mb-1 text-right text-xs">
    <SimpleDropDown values={GetRaceArray()} className="text-xs text-right justify-start float-right"/>
  </div>
</div>
</>
)
}

export function GetRaceAggreementArray() {
    const { t, i18n } = useTranslation();
    return [{id: 'yes', label: t("yes")}, {id: 'no', label: t("no")}, {id: 'partially_diff', label:t("partially_differnet")}];
} 

export function ChuznikimComponent(props) {
    const { t, i18n } = useTranslation();
    const [fromAbroad, setFromAbroad] = useState("");
    const abroadLocalOptions = [{id:'from-abroad',  label: t("origin_abroad")},{id:'agree-abroad',  label: t("agree_abroad")}, {id:'just-israel',  label: t("just_israel")}];
    const abroadTitle = props.gender === "Male" ? t("mark_the_correct_option") : t("mark_the_correct_option_female"); 

    const nations = [{id: 'american', label: t('american')}, {id: 'european', label: t('european')}, {id: 'south-american', label: t('south_american')}, {id: 'nation-other', label: t('other')}];
    const nationsAgreement = [{id: 'american', label: t('american')}, {id: 'european', label: t('european')}, {id: 'south-american', label: t('south_american')}, {id: 'israel', label: t('israel')}];
    const nationsTitle = props.gender === "Male" ? t('nation_title') : t('nations_title_female');
    const nationsAgreementTitle = props.gender === "Male" ? t("nations_agreement") : t("nations_agreement_female") ;

    return (
       <>
    
    <div className="w-full flex flex-row-reverse px-4">
    <div className="relative mb-5">
        <RadioButtonList name="abroadLocal" values={abroadLocalOptions} title={abroadTitle} onChange={setFromAbroad}/>

    </div>
    </div>
   
    {fromAbroad && fromAbroad == "from-abroad" ? (
        
              <div className=" w-full flex flex-row-reverse px-4">
                <div className="relative px-6">
                <RadioButtonList name="nation" values={nations} title={nationsTitle}/>
                   
                  </div>
                   <div className="lg:w-9/12 px-1 mb-8">
                    <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-right"
                    htmlFor="grid-password"
                    >
                    {nationsAgreementTitle}
                    </label>
                    <CheckBoxList values={nationsAgreement}/>
         
                
               </div>   
               </div>
        
            ):  null}     
           
</>
);
}


export function GetProfessionsList(gender){

    const { t, i18n } = useTranslation();
    if (gender && gender == "Female") {
    return [{id: 'computers', label: t('computers')}, {id: 'architecture', label: t('architecture')}, {id: 'nurse', label: t('nurse')}, {id: 'teaching', label: t('teaching')}, {id: 'kinder_gardening', label: t('kindergardening')},
    {id: 'psych', label: t('pshyc')}, {id: 'biotech', label: t('biotech')}, {id: 'accountant', label: t('accountant')}, {id: 'graphics', label: t('graphics')}, {id: 'social_worker', label: t('social_worker')}, {id: 'business_managment', label: t('business_managment')}
, {id: 'photography', label: t('photography')},  {id: 'secratary', label: t('secratary')},  {id: 'therapy', label: t('therapy')},  {id: 'bookkeeping', label: t('bookeeping')},  {id: 'wigs', label: t('wigs')},  {id: 'events_design', label: t('events_design')},  {id: 'other', label: t('other')} ];
    }
    return [{id: 'computers', label: t('computers')}, {id: 'architecture', label: t('architecture')}, {id: 'nurse', label: t('nurse')}, {id: 'teaching', label: t('teaching')}, {id: 'kinder_gardening', label: t('kindergardening')},
    {id: 'psych', label: t('pshyc')}, {id: 'biotech', label: t('biotech')}, {id: 'accountant', label: t('accountant')}, {id: 'graphics', label: t('graphics')}, {id: 'social_worker', label: t('social_worker')}, {id: 'business_managment', label: t('business_managment')}
, {id: 'photography', label: t('photography')},  {id: 'secratary', label: t('secratary')},  {id: 'therapy', label: t('therapy')},  {id: 'bookkeeping', label: t('bookeeping')},  {id: 'wigs', label: t('wigs')},  {id: 'events_design', label: t('events_design')}];
}


export function PersonalityCharactersComponenet(props) {
    const gender = props.gender;
    const { t, i18n } = useTranslation();

    const interests = [{id: 'politics', label: t('politics')}, {id: 'history', label: t('history')}, {id: 'science', label: t('science')}, 
  {id: 'philosophy', label: t('philosophy')}, {id: 'TECH', label: t('tech')}, {id: 'humanity', label: t('humanity')}, {id: 'gloabl', label: t('global')}, 
  {id: 'art', label: t('art')}, {id: 'music', label: t('music')}, {id: 'musar', label: t('musar')}];
  const hobbies = [{id: 'reading', label: t('reading')}, {id: 'writing', label: t('writing')}, {id: 'music', label: t('music')}, {id: 'cooking', label: t('cooking')}, 
  {id: 'sports', label: t('sports')}, {id: 'painting', label: t('painting')}, {id: 'reading-learn', label: t('reading_learn')}, 
  {id: 'extrim', label: t('extrim')}, {id: 'trips', label: t('trips')}, {id: 'languages', label: t('languages')}, {id: 'puzzels', label: t('puzzels')}, 
  {id: 'board-games', label: t('board_games')}];

  const smartnessOptions = [{id: 'genious', label: t('genious')}, {id: 'very-smart', label: t('very_smart')}, {id: 'smart', label: t('smart')} , {id: 'smart-standart', label: t('standart')}];
  const intellegnceOptions = [{id: 'very-intellegent', label: t('very_intellegent')}, {id: 'intellegent', label: t('intellegent')}, {id: 'intellegnce-standart', label: t('standart')}];
  const appearnceOptions = [{id: 'app-very-important', label: t('very_important')},{id: 'app-important', label: t('important')}, {id: 'app-some-important', label: t('some_importance')}, {id: 'app-not-important', label: t('not_important')}];
  const societyOptions = [{id: 'society-very-important', label: t('very_important')},{id: 'society-important', label: t('important')}, {id: 'society-some-important', label: t('some_importance')}, {id: 'society-not-important', label: t('not_important')}];
  const wealthLevelOptions = [{id: 'very-high', label: t('very_high')}, {id: 'high', label: t('high')}, {id: 'standart-level', label: t('standart_female')}, {id: 'under-standart', label: t('under_standart')} ];


    return (
        <>

<hr className="mt-6 border-b-1 border-blueGray-300" />

<h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase justify-end text-right">
  {t("personality")}
</h6>
  <div className=" w-full flex flex-row-reverse px-4">
          <div className="relative mb-5">
          <label
            className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-right"
            htmlFor="grid-password"
          >
           {t("interests")}
          </label>
        
          <CheckBoxList values={interests}/>
          
      </div>
      </div>
      <div className=" w-full flex flex-row-reverse px-4">
          <div className="relative mb-5">
          <label
            className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-right"
            htmlFor="grid-password"
          >
           {t("hobbies")}
          </label>
        
          <CheckBoxList values={hobbies}/>
          
      </div>
      </div>

               {/* smartness + intelleegnce */}
               <div className="flex flex-row-reverse">
          <div className="w-full lg:w-4/12 px-3">
          <div className="relative w-full mb-3">
            <RadioButtonList name="smartness" title={t("smartness_question")} values={smartnessOptions}/>
            </div>
          </div>

          <div className="w-full lg:w-4/12 px-3">
          <div className="relative w-full mb-3">
            <RadioButtonList name="intellegnce" title={t("intellegence")} values={intellegnceOptions}/>
            </div>
          </div> 
          </div>

                 {/* society + appearnce */}
          <div className="flex flex-row-reverse">
          <div className="w-full lg:w-4/12 px-3">
          <div className="relative w-full mb-3">
            <RadioButtonList name="appearence" title={t("apperance_question")} values={appearnceOptions}/>
            </div>
          </div>

          <div className="w-full lg:w-4/12 px-3">
          <div className="relative w-full mb-3">
            <RadioButtonList name="society" title={t("society_influence")} values={societyOptions}/>
            </div>
          </div> 
          </div>

        </>
    );
}

export function LifeStyleComponent(props) {
    const { t, i18n } = useTranslation();
    const gender = props.gender;
    const fromAbroad = props.fromAbroad;
    const setFunctions = props.setFunctions;
    const { setFamilyImportance, setMoneyDemands, setMoneyGives } = setFunctions;
    const {familyImportance, moneyDemands, moneyGives} = props.stateProps;

    const wealthLevelOptions = [{id: 'very-high', label: t('very_high')}, {id: 'high', label: t('high')}, {id: 'standart-level', label: t('standart_female')}, {id: 'under-standart', label: t('under_standart')} ];
    const livingPrefer = [{id: 'no-prefer', label: t('no_prefer')}, {id: 'mixed-prefer', label: t('mixed_prefer')}, {id: 'charedi-prefer', label: t('charedi_prefer')}];
    const livignNegative = [{id: 'jerusalem', label: t('jerusalem')}, {id: 'bney-brak', label: t('bney_brak')}, {id: 'modiin-ilit', label: t('modiin_ilit')}, {id: 'elad', label: t('elad')}, {id: 'south-area', label: t('south_area')}
    ,{id: 'north-area', label: t('north_area')}, {id: 'centeral', label: t('centeral')}];
    const livingObligation = [{id: 'jerusalem', label: t('jerusalem')}, {id: 'bney-brak', label: t('bney_brak')}, {id: 'modiin-ilit', label: t('modiin_ilit')}, {id: 'elad', label: t('elad')}, {id: 'south-area', label: t('south_area')}
    ,{id: 'north-area', label: t('north_area')}, {id: 'centeral', label: t('centeral')}, {id: 'no-obligation', label: t('no')}];
  
    const yesNoOptioms = [{id: 'yes', label: t('yes')}, {id: 'no', label: t('no')}];
    const familyDefintion = [{id: 'avrech', label: t('avrech')}, {id: 'jobs', label: t('jobs')}, {id: 'workers', label: t('workers')},  {id: 'chozrim-bitshuva', label: t('chozrim_bitshuva')}, {id: 'undefined', label: t('undefined')} ];
    const familyStyle =  [{id: 'regular', label: t('regular')}, {id: 'important', label: t('important')}, {id: 'yichus', label: t('yichus')}];
    const moneyGivesOptions = [{id: 'depends', label: t('depends')}, {id: 'same_as', label: t('same_as')}, 
    {id: 'not_interested_to_mention', label: t('not_interested_to_mention')}, {id: 'specific-schum', label: t('specific_schum')}];

    
return (
<>
   {/* life style  */}
   <hr className="mt-6 border-b-1 border-blueGray-300" />
   <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase justify-end text-right">
     {t("life_title")}
   </h6>
               {/* wealth level */}
               <div className="flex flex-row-reverse">
               <div className="w-full lg:w-4/12 px-3">
               <div className="relative w-full mb-3">
                 <RadioButtonList name="wealthLevelToday" title={t("lifestyle_today")} values={wealthLevelOptions}/>
                 </div>
               </div>

               <div className="w-full lg:w-4/12 px-3">
               <div className="relative w-full mb-3">
                 <RadioButtonList name="wealthLevelFuture" title={t("lifestyle_future")} values={wealthLevelOptions}/>
                 </div>
               </div> 
               </div>

                {/* living  place */}
               <div className="w-full flex flex-row-reverse px-4">
               <div className="relative mb-5">
               <RadioButtonList name="livingClassificationPrefer" title={t("living_prefer")} values={livingPrefer}/>
                 </div>
               </div>
             <div className=" w-full flex flex-row-reverse px-4">
               <div className="w-full lg:w-6/12 px-4">
               <div className="relative w-full mb-3">
               <label
                 className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-right"
                 htmlFor="grid-password"
               >
                {t("living_negative")}
               </label>
             
               <CheckBoxList values={livignNegative}/>
               
               </div>
               </div>

               <div className="w-full lg:w-6/12 px-4">
               <div className="relative w-full mb-3">
                 <RadioButtonList name="livingObligation" title={t("living_obligation")} values={livingObligation}/>
                 </div>
               </div>
               </div>

               {fromAbroad && fromAbroad == "from-abroad" ? (
                 <div className="w-full flex flex-row-reverse px-4">
                 <div className="relative mb-5">
                 <RadioButtonList name="livingAbroad" title={t("abraod_living_plans")} values={yesNoOptioms}/>
                   </div>
                 </div>
               ): null}
      
      {/* family*/}
             <div className="flex flex-row-reverse">
               <div className="w-full lg:w-4/12 px-3">
               <div className="relative w-full mb-3">
                 <RadioButtonList name="familyDefinition" title={t("family_declartion")} values={familyDefintion}/>
                 </div>
               </div>

               <div className="w-full lg:w-4/12 px-3">
               <div className="relative w-full mb-3">
                 <RadioButtonList name="familyStyle" title={t("family_importance")} onChange={setFamilyImportance} values={familyStyle}/>
                 </div>
               </div> 
             </div>
     
         {familyImportance && familyImportance == "yichus" || familyImportance == "important" ? (
            <div className="w-full flex flex-row-reverse px-4">
            <div className="w-full lg:w-4/12 px-3">
               <div className="relative w-full mb-3">
            <RadioButtonList name="simpleFamily" title={t("simple_bother")} values={yesNoOptioms}/>
              </div>
            </div>
          
           {familyImportance == "yichus" ? (
             <div className="w-full lg:w-4/12 px-3">
               <div className="relative w-full mb-3">
            <RadioButtonList name="yichusImportant" title={t("yichus_important")} values={yesNoOptioms}/>
              </div>
            </div>
          
            ): null }
              </div>
         ): null}

       {/* money*/}

       <div className="w-full flex flex-row-reverse px-4">
            <div className="w-full lg:w-4/12 px-3">
               <div className="relative w-full mb-3">
            <RadioButtonList name="moneyDemands" title={t("money_demands")} onChange={setMoneyDemands} values={yesNoOptioms}/>
              </div>
            </div>
            {moneyDemands && moneyDemands == "yes" ? (
              <div className="w-full lg:w-4/12 px-3">
              <div className="relative w-full mb-3">
              <label
                   className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-right"
                   htmlFor="grid-password"
                 >
                   {t("please_enter_the_amount")}
                 </label>
                 <input
                   type="text"
                   className="border-0 px-3 py-3 text-right placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  
                 />
             </div>
           </div>

            ) : null}
       </div>

       <div className="w-full flex flex-row-reverse px-4">
            <div className="w-full lg:w-4/12 px-3">
               <div className="relative w-full mb-3">
            <RadioButtonList name="moneyGives" title={t("interested_to_give")} onChange={setMoneyGives} values={moneyGivesOptions}/>
              </div>
            </div>
            {moneyGives && moneyGives == "specific-schum" ? (
              <div className="w-full lg:w-4/12 px-3">
              <div className="relative w-full mb-3">
              <label
                   className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-right"
                   htmlFor="grid-password"
                 >
                   {t("please_enter_the_approx")}
                 </label>
                 <input
                   type="text"
                   className="border-0 px-3 py-3 text-right placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  
                 />
             </div>
           </div>

            ) : null}
       </div>
       </>
       );
}

