import React, { useState, useEffect, setState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
//import "./Login.css";

import Navbar from "../components/Navbars/AuthNavbar.js";
import { sendUnauthenticatedRequest } from "../components/rest.js";

export default function ResetPassword() {
  
    const history = useHistory();
    const { t, i18n } = useTranslation();

    const [password, setPassword] = useState("");
    const [weakPassword, setWeakpassword] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [showPassVerification, setShowPassVerification] = useState(false);
    const [passwordVerification, setPasswordVerification] = useState("");
    const [notMatchingPasswords, setNotMatchingPasswords] = useState(false);

    const search = useLocation().search;
    const userId = new URLSearchParams(search).get('id');
    const token = new URLSearchParams(search).get('token');
    if (!userId || !token) {
        history.push('/landing')
    }

    async function handleSubmit(event) {
        event.preventDefault();

        const url = '/user/reset_password';
        //const url = '/user/set';
        const requestBody = {
            userId: userId,
            token: token,
            newPassword: password
        }
        try {            
            await sendUnauthenticatedRequest(url, 'POST', JSON.stringify(requestBody));
            history.push('/login')
        } catch(err) {
            alert(t('interal_error'));
        }

    }

  return (
    <>
      <Navbar transparent />
      <main className="profile-page">
        <section className="relative block h-500-px">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover bg-wed-flowers"
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-50 bg-black"
            ></span>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-blueGray-200 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </section>
       
        <section className="pb-20 bg-blueGray-200 -mt-64">
          <div className="container mx-auto">
          <div className="flex content-center items-center justify-center h-full">
         
            <div className="w-full lg:w-6/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words bg-blueGray-50 w-full mb-8 shadow-xl rounded-lg">
                <div className="px-8 py-5 flex-auto">
                    {/* <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-400"> */}
            {/* <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0 mb-6">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center"> */}
     
                  <div className="text-center mt-8">
                  <h6 className="text-blueGray-800 text-center font-queryFont3 font-bold text-2xl mb-10 font-bold">
                  {t("reset_password_title")}
                  </h6>
                  <form>
                  <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-sm text-right mb-2"
                          htmlFor="grid-password"
                        >
                         {t("new_password")}
                        </label>
                        <input
                          type={showPass ? "text" :"password"}
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm text-right shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder= {t("signup_password")}
                          onChange={(e) => {setWeakpassword(false) ; setNotMatchingPasswords(false) ;setPassword(e.target.value)}}
                        />
                        { showPass? 
                          (<i className="absolute fas fa-eye-slash left-0 pl-3 items-center mt-3" onClick={() => setShowPass(false)}></i>):
                          (<i className="absolute fas fa-eye left-0 pl-3 items-center mt-3" onClick={() => setShowPass(true)}></i>)
                        }
                      </div>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-sm text-right mb-2"
                          htmlFor="grid-password"
                        >
                         {t("signup_password_verification")}
                        </label>
                        <input
                          type={showPassVerification ? "text" :"password"}
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm text-right shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder= {t("signup_password_verification_placeholder")}
                          onChange={(e) => {setNotMatchingPasswords(false); setPasswordVerification(e.target.value)}}
                        />
                        { showPassVerification? 
                          (<i className="absolute fas fa-eye-slash left-0 pl-3 items-center mt-3" onClick={() => setShowPassVerification(false)}></i>):
                          (<i className="absolute fas fa-eye left-0 pl-3 items-center mt-3" onClick={() => setShowPassVerification(true)}></i>)
                        }
                      </div>
                      </form>
                    
                    {/* { showPass? 
                     (<i className="fas fa-eye-slash" onClick={setShowPass(false)}></i>):
                     (<i className="fas fa-eye" onClick={setShowPass(true)}></i>)
                      } */}
                    </div>
                    <div className="text-center mt-10 mb-8">
                      <button
                        className="bg-blueGray-800 font-queryFont3 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                        onClick={handleSubmit}
                      >
                      {t("reset_password_submit")}
                      </button>
                    </div>
                    

                  </div>
                  </div>
            
                  </div>
                  </div>
                  </div>
         
        </section>
        
      </main>
      {/* <Footer /> */}
    </>
  );
}




